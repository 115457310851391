import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {BulletinsService} from '../../services/bulletins/bulletins.service';
import {Bulletins} from '../../models/bulletins-details';
import {MessageService, ConfirmationService, Message} from 'primeng/api';
import {UtilService} from '../../services/util.service';
import {ApsService} from '../../aps.service';
import { DatePipe } from '@angular/common';

@Component({
selector: 'manage-bulletins',
templateUrl: './manage-bulletins.component.html'
	//, styleUrls: ['./manage-bulletins.component.scss']
})
export class ManageBulletinsComponent implements OnInit {

constructor(private router: Router,
			private aRouter: ActivatedRoute,
			private datePipe: DatePipe,
			private bulletinService: BulletinsService,
			private apsService: ApsService,
			private utilService: UtilService,
			private messageService: MessageService,
			private confirmationService: ConfirmationService) {
	}
	bulletins: Bulletins[];
	message: Message[] = [];
	success: string = '';

	role: string = '';
	isBulletinAccess:boolean = true;

	ngOnInit() {
		this.success = null;
		this.getRoles();
		this.findAll();
	}

	private getRoles() {
		console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
		this.apsService.getRoles().subscribe(roles => {
			if (roles != null && roles !== '') {
				if ((roles === 'eSRA_Admin' || roles === 'eSRA_SuperUser')) {
					//if((roles != 'eSRA_Admin')){
					this.isBulletinAccess = true;
				} else {
					this.isBulletinAccess = false;
				}
			} else {
				this.router.navigate(['no-access']);
			}
		});
	}

	onCreate() {
		this.router.navigate(['/bulletin/create-bulletins/create/0']);
	}

	private findAll() {
		this.bulletinService.findAll().subscribe((response: Bulletins[]) => {
			this.bulletins = response;
			this.bulletins.forEach(bulletin => {
				bulletin.linksArray = bulletin.linkText.split(' ');
			});
		});
	}

	onDelete(id: number) {
		this.success = '';
		this.confirmationService.confirm({key: 'confirm-DeleteBulletin', message: 'Are you sure you would like to delete?',
				reject: () => { this._deleteBulletinNo(); },  accept: () => { this._deleteBulletinYes(id); },
		});
	}

	private _deleteBulletinNo() {
		console.log('Bulletin deletion dropped');
	}

	private _deleteBulletinYes(id) {
		this.bulletinService.delete(id).subscribe(value => {
			this.utilService.pushMsg('success', 'Success', 'Bulletin has been deleted successfully');
			this.findAll();
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Unable to delete Bulletin!');
		});
	}

	onEdit(id: number) {
		this.success = '';
		this.router.navigate(['bulletin/create-bulletins/edit/' + id]);
	}

	private retrieveLink(plainText): string {
		return this.utilService.linkify(plainText);
	}

	private dateTest(value: string) {
		return value + ' GMT+0';
	}
}

import { Component, OnInit } from '@angular/core';
import {environment} from '../../environments/environment';

@Component({
selector: 'no-access',
templateUrl: './no-access.component.html'
	//,styleUrls: ['./no-access.component.scss']
})
	export class NoAccessComponent implements OnInit {
		public apsUrl = environment.apsLink;
constructor() { }
//tabName :string;
//role:string='';
ngOnInit() {
}

}

  
import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';

/** This component handles redirecting users to the auth provider */
@Component({
	template: `
		<h4>Redirecting to login server</h4><br><br><br><br><br><br><br><br><br><br><br><br><br>
		<br><br><br><br><br><br><br><br><br><br><br><br><br><br>
	`
})
export class LoginComponent implements OnInit {

	/**  the configured URL endpoint to send the user's browser to for token negotiation */
	
	private env = environment.envName;
	private _endPoint = environment.envEndPoint;

	/** once the component is up and running, redirect the user to the auth provider */
	ngOnInit() {
		if(this.env == 'dev' || this.env == 'qa' || this.env == 'prod'){
			console.log("Log In Environemnt::"+this.env);
			window.location.replace(this._endPoint);
		}else{
			console.log("Log In Environemnt::"+this.env);
		}
	}
}
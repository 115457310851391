import {Component} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {ApsService} from '../aps.service';
import {UrlConsumerService} from '../oauth/url-consumer.service';
import {ActivatedRoute} from '@angular/router';

/** Angular component that utilizes the PrimeNG p-menubar component
 * to display the navigation menu for the application
 */
@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.css']
})
export class NavComponent {
	/** Array of PrimeNG MenuItems that are used to populate the p-menubar */
	navMenuItems: MenuItem[];
	showNavBar = true;
	role: string ;

	navRole : any;
	reportTab : any;

	/** whether or not the sidebar is open or not */
	sidebarVisible: boolean;
	/** toggles visibility of sidebar to true */
	showSidebar = () => { this.sidebarVisible = true; };
	/** toggles visibility of sidebar to false */
	hideSidebar = () => { this.sidebarVisible = false; };

	constructor(private apsService: ApsService,private urlConsumerService: UrlConsumerService,
					public route: ActivatedRoute) {	}

	ngOnInit(): void {

		if (window.location.href.includes('eprint')) {
			this.showNavBar = false;
		}
		this.urlConsumerService.apsAttributesSubject.subscribe(attributes => {
			const attributeString = JSON.stringify(attributes);
			console.log('User Role in nav component after JSON Parse ::'+JSON.parse(attributeString));

			if(JSON.parse(attributeString) === 'eSRA_Admin'){
				this.navRole = true;
				this.navigation();
				console.log('display Manage Bulletins tab');
			}else {
				this.navRole = false;
				this.navigation();
				console.log('hide Manage Bulletins tab');
			}

			/*if(JSON.parse(attributeString) === 'eSRA_JV-Buyer' || JSON.parse(attributeString) === 'eSRA_JV-STA-Engineer' || JSON.parse(attributeString) === 'eSRA_JV-STA-Supervisor' ||
				JSON.parse(attributeString) === 'SIM_JV-STA-Engineer' || JSON.parse(attributeString) === 'SIM_JV-STA-Supervisor' || JSON.parse(attributeString) === 'SIM_JV-STA-Manager'){
				this.reportTab = false;
				this.navigation();
				console.log('hide Reports tab');
			}
			else{
				this.reportTab = true;
				this.navigation();
				console.log('display Reports tab');
			}*/
		});

		if((sessionStorage.getItem('navUserRole') === 'eSRA_Admin')){
			this.navRole = true;
			this.reportTab = true;
			this.navigation();
			console.log('display Manage Bulletins tab - inside session');
		} else {
			this.navRole = false;
			this.reportTab = false;
			this.navigation();
			console.log('hide Manage Bulletins tab - inside session');
		}

		/*if((sessionStorage.getItem('navReportRole') === 'eSRA_JV-Buyer') ||
			(sessionStorage.getItem('navReportRole')  === 'eSRA_JV-STA-Engineer') ||
			(sessionStorage.getItem('navReportRole')  === 'eSRA_JV-STA-Supervisor') ||
			(sessionStorage.getItem('navReportRole')  === 'SIM_JV-STA-Engineer') ||
			(sessionStorage.getItem('navReportRole')  === 'SIM_JV-STA-Supervisor') ||
			(sessionStorage.getItem('navReportRole')  === 'SIM_JV-STA-Manager')){
			this.reportTab = false;
			this.navigation();
			console.log('display Reports tab - inside session');
		}
		else{
			this.reportTab = true;
			this.navigation();
			console.log('hide Reports tab - inside session');
		}*/
	}

	navigation(){
		console.log("Menus Display");
		this.navMenuItems = [
			{
				label: 'Home',
				routerLink: '/home',
				//visible: true,
				//id: 'home',
				command: this.hideSidebar
			},
			{
				label: 'Manage Bulletins',
				routerLink: '/bulletin/manage-bulletins',
				visible: this.navRole,
				id: 'bulletins'
			},
			{
				label: 'Reports',
				routerLink: '/reports/reports',
				//visible:this.reportTab,
				visible: true,
				id: 'reports'
			},
			{
				label: 'Help',
				routerLink: '/help',
				visible: true,
				id: 'help',
				styleClass:''
			},
		];
	}
}

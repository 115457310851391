import { Injectable } from '@angular/core';
import {
	Observable,
	Subscription
} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

	//private apiURL = environment.envBaseURL+'/siteCodeDetails/';
	private apiURL = environment.envBaseURL;

	public excelReport(selectedSiteCodes,selectedSuppliers,selectedPrograms,selectedCommodities,selectedPMT,selectedVHM,keyCommodity1,esfCommodity1,
					   greenBrownFields1,psaCheckedList,qualityCheckedList,capacityCheckedList,supplierCheckedList,
					   programCheckedList,staCheckedList,qualityMitPlan,capacityMitPlan,supplierMitPlan,programMitPlan,ci,selectedSTAOrgCodes,
					   employeeType,companyDomain,companyName): Observable<any> {
		const httpOptions = {responseType: 'blob' as 'json'};
		return this.http.put<any>(this.apiURL+'excelReport',{selectedSiteCodes,selectedSuppliers,selectedPrograms,selectedCommodities,selectedPMT,selectedVHM,keyCommodity1,esfCommodity1,
			greenBrownFields1,psaCheckedList,qualityCheckedList,capacityCheckedList,supplierCheckedList,programCheckedList,staCheckedList,qualityMitPlan,
			capacityMitPlan,supplierMitPlan,programMitPlan,ci,selectedSTAOrgCodes,employeeType,companyDomain,companyName},httpOptions);
	}

	public getSiteCodes(userCheck,role,companyDomain,employeeType,companyName): Observable<any> {
		return this.http.get(this.apiURL+'getSiteCodes/'+userCheck+'/'+role+'/'+companyDomain+'/'+employeeType+'/'+companyName);
	}

	public getSuppliers(userCheck,role,companyDomain,employeeType,companyName): Observable<any> {
		return this.http.get(this.apiURL+'getSuppliers/'+userCheck+'/'+role+'/'+companyDomain+'/'+employeeType+'/'+companyName);
	}

	public getPrograms(userCheck,role,companyDomain,employeeType,companyName): Observable<any> {
		return this.http.get(this.apiURL+'getPrograms/'+userCheck+'/'+role+'/'+companyDomain+'/'+employeeType+'/'+companyName);
	}

	public getCommodities(userCheck,role,companyDomain,employeeType,companyName): Observable<any> {
		return this.http.get(this.apiURL+'getCommodities/'+userCheck+'/'+role+'/'+companyDomain+'/'+employeeType+'/'+companyName);
	}

	public getPMT(userCheck,role,companyDomain,employeeType,companyName): Observable<any> {
		return this.http.get(this.apiURL+'getPMT/'+userCheck+'/'+role+'/'+companyDomain+'/'+employeeType+'/'+companyName);
	}

	public getVHM(userCheck,role,companyDomain,employeeType,companyName): Observable<any> {
		return this.http.get(this.apiURL+'getVHM/'+userCheck+'/'+role+'/'+companyDomain+'/'+employeeType+'/'+companyName);
	}

	public getSTAOrgCodes(): Observable<any> {
		return this.http.get(this.apiURL+'getSTAOrgCodes');
	}

	public getSTAOrgCodesAndSiteCodes(): Observable<any> {
		return this.http.get(this.apiURL+'getSTAOrgCodesAndSiteCodes');
	}
}

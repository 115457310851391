export class GetPartData {
	partName: string;
	suffix:string;
	prefix:string;
	base:string

	constructor(getpartdata: GetPartData) {
		Object.assign(this, getpartdata);
		//alert(this.partName+"-----part>>>>---")
	}
}




import { Injectable } from '@angular/core';
//import * as FileSaver from 'file-saver';
//import * as XLSX from 'xlsx';
import * as _  from 'lodash';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

//headerInfo = ['certHolderName', 'certTypeName', 'completionDate', 'expirationDate', 'isExpired'];


@Injectable()
export class ExcelService {
  constructor() { }


  public exportAsExcelFile(json: any[], excelFileName: string): void {

    let excelData = JSON.parse(JSON.stringify(json));
    
    excelData.splice(0, 0, { psaNumber : "PSA Number", modelYear: "Model Year/Program",commodityCode:"Commodity Code", siteCode:"Site Code",buyer:"Buyer",
    staEng:"STA Engineer",	staEngLL6:"STA Engineer LL6",	psaVerified:"PSA Details Verified",	supplierName:"Supplier Name",	city:"City",	state:"State",	country:"Country",
    q1Score:"Q1 Score",	q1Status:"Q1 Code",	ppm:"PPM",	openwrntySpikes:"Warranty Performance",	stopshipments:"Stop Shipments in last 6 Months",	openFSA:"Field Service Actions in last 6 months(including recovery)	",	dlySixMonthsAvg:"Delivery 6 month average",	priorLaunchPrf:"Prior Launch Performance",	qltySysQ1MSA:"Quality System - Q1 MSA	",	qualtiyRiskColor:"Overall Quality Risk",
      q1ScoreColor:"Q1 Score Color",	q1CodeColor:"Q1 Code Color",	ppmColor:"PPM Color",	openwrntySpikesColor:"Warranty Performance Color",	stopshipmentsColor:"Stop Shipments in last 6 Months Color",	openFSAColor:"Field Service Actions in last 6 months(including recovery) Color",	dlySixMonthsAvgColor:"Delivery 6 month average Color",	priorLaunchPrfColor:"Prior Launch Performance Color",
        qltySysQ1MSAColor:"Quality System - Q1 MSA Color",	qltyRiskSTAComments:"STA Comments",	qltyRiskComments:"Buyer Comments",	qltyRiskActionPlan:"Action Plan",	qltyRiskOwner:"Owner",	qltyRiskMitDate:"Mitigation Target Plan Date",
        	qltyRiskQ1Code:"Mitigation Plan for Q1 Code",	
        qltyRiskQ1Score:"Mitigation Plan for Q1 Score",	qltyRiskPPM:"Mitigation Plan for PPM",	qltyRiskWarranty:"Mitigation Plan for Warranty Performance",	
        qltyRiskStopships:"Mitigation Plan for Stop Shipments in last 6 Months",	
        qltyRiskFSA:"Mitigation Plan for Field Service Actions in last 6 months(including recovery)",	qltyRiskDlry:"Mitigation Plan for Delivery 6 month average",	qltyRiskPriorLaunch:"Mitigation Plan for Prior Launch Performance",	qltyRiskQ1msa:"Mitigation Plan for Quality System - Q1 MSA",


        sharedCpty:"Shared Capacity",	localLabor:"Local Labor / Workforce Expertise",	opePattern:"Operating Pattern",	manfReadiness:"Manufacturing/Capital Equipment Readiness",	overallCapRisk:"Overall Capacity Risk",	
        capRiskSTAComments:"STA Comments",	capRiskComments:"Buyer Comments",	capRiskSharedCpty:"Mitigation Plan for Shared Capacity",
          capRiskLcalLabor:"Mitigation Plan for Local Labor / Workforce Expertise",	capRiskOpePattern:"Mitigation Plan for Operating Pattern",	capRiskManfReadiness:"Mitigation Plan for Manufacturing/Capital Equipment Readiness",	
          capRiskActionPlan:"Action Plan",	capRiskOwner:"Owner",	capRiskMitDate:"Mitigation Target Plan Date",
            techCapability:"Technical Capability and Resources",
            siteLdrCpty:"Site Leadership Stability",	finRisk:"Financial Risk",	ownership:"Ownership",	localToolCpty:"Tool Capability",	mulLaunch:"Multiple Launches/Programs",	ilvsExp:"ILVS Experience",	subtierMgmt:"Sub-Tier Management",	ll4Site:"Level 4 Site (Travel Restricted)",	healthChartColor:"Health Chart Compliant",	overallSupRisk:"Overall Supplier Risk",
              supRiskSTAComments:"STA Comments",	supRiskComments:"Buyer Comments",	supRiskHealthChart:"Mitigation Plan for Health Chart Compliant",	
              supRiskTechCapability:"Mitigation Plan for Technical Capability and Resources",	supRiskSiteLdrCpty:"Mitigation Plan for Site Leadership Stability	",	supRiskFinRisk:"Mitigation Plan for Financial Risk",
                supRiskOwnership:"Mitigation Plan for Ownership",	supRiskLocalToolCpty:"Mitigation Plan for Tool Capability",	supRiskMulLaunch:"Mitigation Plan for Multiple Launches/Programs",	supRiskIlvsExp:"ILVS Experience	",	supRiskSubtierMgmt:"Sub-Tier Management",
                	supRiskActionPlan:"Action Plan",
                  supRiskOwner:"Owner",	supRiskMitDate:"Mitigation Target Plan Date",	supRiskLL4Site:"Mitigation Plan for Level 4 Site (Travel Restricted)"	,
                  
                  dt2Mpa:"DT2 / MPA / Bailment",	newDsnTech:"New Design, Manufacturing Technology or Material",	gtcCompl:"GT&C Compliance",	plannesPSW:"Planned PSW Timing",	overallParentRisk:"Overall Program Risk",
                    proRiskSTAComments:"STA Comments",	proRiskComments:"Buyer Comments",	proRiskActionPlan:"Action Plan",	proRiskOwner:"Owner",	proRiskMitDate:"itigation Target Plan Date",	
                    proRiskDt2Mpa:"Mitigation Plan for DT2 / MPA / Bailment",	proRiskNewDsnTech:"Mitigation Plan for New Design, Manufacturing Technology or Material",	proRiskGtcCompl:"Mitigation Plan for GT&C Compliance",	proRiskPlannesPSW:"Mitigation Plan for Planned PSW Timing"	

                    ,staComments:"STA Comments",	ll6Verified:"eSRA is STA Verified & Reviewed with STA LL6",	currentDate:"Time Stamp",buyerComments:"Buyer Comments",	recSubmittedDate:"eSRA Submitted Date",	recCreatedDate:"eSRA Created Date",	recCreatedUser:"eSRA Created User",	recLastUpdatedDate:"Last Modified Date",	recLastUpdatedUser:"Last Modified User",	recordComplete:"eSRA Completed"
                  }); 
    //const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData,{header: [], skipHeader: true});
    
    //const workbook: XLSX.WorkBook = { Sheets: { 'eSRA_Suppliers_Report': worksheet }, SheetNames: ['eSRA_Suppliers_Report'] };
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    //FileSaver.saveAs(data, fileName + '_' + new Date().getUTCFullYear()+'_'+new Date().getUTCMonth()+'_'+new Date().getUTCDate()+
                    // EXCEL_EXTENSION);
  }
}
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Location} from '@angular/common';
import {UserIdService} from '../services/user-id.service.service';
import {ApsService} from '../aps.service';
import {ApsAttributes} from '../models/aps-attributes';
import {Subject} from 'rxjs';

/** This guard is used in the oauth callback flow, consuming the URL before the Angular router can.
 * The URL built by the auth provider is technically invalid (no ? for the queryParams), so this guard
 * needs to consume the URL before Angular's router (which would fail to parse it).*/

@Injectable()
export class UrlConsumerService implements CanActivate {

	apsAttributesSubject = new Subject<any>();

	/** Creates an instance of the UrlConsumerService
	 *
	 * @param router route instance for current routing params
	 * @param location the angular location service for interacting with the browser location object
	 * @param idService the angular service for handling user ID*/

	constructor(private router: Router, private location: Location, private idService: UserIdService,private apsService: ApsService) {
	}

	/** the actual guard fuction. Parses the queryString and stores the params in sessionStorage.
	 * Redirects the user to the default route, or to the route that was stored before the auth redirect.
	 *
	 * @param route the snapshot of the current ActivatedRoute
	 * @param state the snapshot of the current RouterState
	 * @returns whether route can be activated or not*/

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		console.log('Url consumer service called');
		const queryParamsObj = this.getQueryParams();

		if (queryParamsObj && queryParamsObj['access_token'] != null) {
			//token is part 2 of a JWT (index 1)
			const accessToken = atob(queryParamsObj['access_token'].split('.')[1]);
			const jsonToken = JSON.parse(accessToken);
			console.log('Token Received');

			//store off token information in the appropriate places
			this.storeTokenInformation(accessToken, queryParamsObj['access_token'], jsonToken);

			// Redirect to originally requested URL
			if (sessionStorage.getItem('redirectURL')) {
				this.router.navigate([sessionStorage.getItem('redirectURL')]);
				return false;
			} else{
				this.router.navigate(['home']);
			}
			return true;
		} else {
			console.error('Invalid Token');
		}
		return false;
	}

	/** This function set values in sessionStorage from the given parameters
	 *
	 * @param strAccessToken string representation of the access token
	 * @param encodedAccessToken encoded JWT representation of the access token as sent on the url
	 * @param jsonToken parsed representation of the access token
	 */
	storeTokenInformation(strAccessToken, encodedAccessToken, jsonToken) {
		console.log('setting token information');
		sessionStorage.setItem('strAccessToken', strAccessToken);
		sessionStorage.setItem('encodedAccessToken', encodedAccessToken);
		sessionStorage.setItem('tokenIssue', jsonToken.iat);
		sessionStorage.setItem('tokenExp', jsonToken.exp);
		sessionStorage.setItem('userId', jsonToken.CommonName);
		sessionStorage.setItem('userName', jsonToken.givenName);
		let displayName = jsonToken.sn + ', ' + jsonToken.givenName;
		if (jsonToken.initials !== null && jsonToken.initials !== undefined) {
			displayName = displayName + ' (' + jsonToken.initials + ')';
		}
		sessionStorage.setItem('displayLeadName', displayName);
		sessionStorage.setItem('userMailId', jsonToken.mail);
		sessionStorage.setItem('employeeType', jsonToken.employeeType);
		sessionStorage.setItem('companyName', jsonToken.fordCompanyName);
		sessionStorage.setItem('companyCode', jsonToken.fordCompanyCode);
		let domainName =  jsonToken.mail;
		if(domainName !== null) {
			sessionStorage.setItem("domainName",domainName.split('@')[1]);
		}
		this.idService.setUserID(jsonToken.CommonName);
		this.idService.setUserName(jsonToken.givenName);
		this.idService.setEmployeeType(jsonToken.employeeType);
		this.idService.setCompanyName(jsonToken.fordCompanyName);
		this.idService.setCompanyCode(jsonToken.fordCompanyCode);
		this.idService.setMail(sessionStorage.getItem("domainName"));

		this.apsService.getRoles().subscribe( async attributes => {
			if (attributes == null || attributes === '') {
				console.log('No Role Assigned');
				sessionStorage.removeItem('encodedAccessToken');
				this.router.navigate(['no-access']);
			} else {
				const attributeString = JSON.stringify(attributes);
				await sessionStorage.setItem('navUserRole', JSON.parse(attributeString));
				this.idService.setRole(sessionStorage.getItem("navUserRole"));
				this.apsAttributesSubject.next(attributes);
				console.log("User Role in Url consumer service ::" + sessionStorage.getItem('navUserRole'));
			}
		});
	}

	/** Parses the technically malformed queryString to pick off the token and associated properties.
	 * @returns The queryString params in Object format, or null if the string was invalid.*/

	getQueryParams() {
		if (this.location.path(true).indexOf('access_token') === 0) {
			const queryString = this.location.path(true);

			//URLSearchParams should be the solution here. it's not working. so we did it manually
			const paramArray = queryString.split('&');
			const queryParamsObj = new Object();

			for (const param of paramArray) {
				//we can't use a simple split() call here as base64 allows for = padding
				const i = param.indexOf('=');
				const splitArray = [param.slice(0, i), param.slice(i + 1)];
				queryParamsObj[splitArray[0]] = splitArray[1];
			}
			return queryParamsObj;
		} else {
			return null;
		}

	}
}

import { Component, OnInit} from '@angular/core';
import {SiteriskdetailsService} from '../../services/siteriskdetails.service';
import {RetrieveeSRA} from '../../models/retrieveeSRA-details';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {Message, SelectItem, ConfirmationService} from 'primeng/api';
import {ApsService} from '../../aps.service';
import {CommentsSRA} from '../../models/comments';
import {UtilService} from '../../services/util.service';
import {GetPartData} from '../../models/part-details';
import {SitedetailsService} from '../../services/sitedetails.service';
import {UpdateeSRA} from '../../models/updateeSRA-details';
import {MessageService} from 'primeng/api';

@Component({
	selector: 'viewesra',
	templateUrl: './viewesra.component.html',
	styleUrls: ['./viewesra.component.scss']
})

export class ViewesraComponent implements OnInit {
	colors: SelectItem[];
	site: boolean = false;
	psa: boolean = false;
	psaAndSite: boolean = false;
	message: Message[] = [];



	siteCodeOrPSA: string;
	type: string;
	textColor: string;
	siteCode: string;
	psaNumber: string;
	printSite: string  = '';
	printPsa: string = '';
	reopen: string = '';
	NA: string = 'Not Available';
	overallTextColor: string;

	commentsSRA: CommentsSRA;
	reAssignRequest: UpdateeSRA;
	siteDetails: RetrieveeSRA[];
	psaSiteDetails: RetrieveeSRA[];
	role: '';

	checked: boolean = true;
	unChecked: boolean = false;
	spinnerFlag: boolean = true;

	currentBuyer: string;

	errorMsg = 'Unable to retrieve Site details , Please try again later';

	qltyColExp: boolean = true;
	qltyCollpaseLable: string = '-';
	capColExp: boolean = true;
	capCollpaseLable: string = '-';
	supColExp: boolean = true;
	supCollpaseLable: string = '-';
	proColExp: boolean = true;
	proCollpaseLable: string = '-';
	//Mit Plan
	qltyMitColExp: boolean = true;
	qltyMitCollpaseLable: string = '-';

	capMitColExp: boolean = true;
	capMitCollpaseLable: string = '-';

	supMitColExp: boolean = true;
	supMitCollpaseLable: string = '-';

	proMitColExp: boolean = true;
	proMitCollpaseLable: string = '-';

	partStatus: string;
	partDetails: GetPartData[];

	displayModal: boolean;
	reassign: boolean;
	reassignBuyer: string = null;
	errorMsgBuyer:string;
	userID:string;

	constructor(private router: Router, private siteRiskService: SiteriskdetailsService,private siteService: SitedetailsService,private messageService: MessageService,
				private aRouter: ActivatedRoute, private apsService: ApsService, private confirmationService: ConfirmationService,private utilService: UtilService) {
	}

	ngOnInit() {
		this.getRoles();
		this.retrieveData();
		this.userID = sessionStorage.getItem("userID")
		console.log("UserID form Session Storage in View Screen::"+this.userID );
	}

	private getRoles() {
		this.spinnerFlag = true;
		this.apsService.getRoles().subscribe(roles => {
			this.role = roles;
		});
	}

	private retrieveData() {
		this.aRouter.paramMap.subscribe((paramMap: ParamMap) => {
			this.siteCodeOrPSA = paramMap.get('data');
			this.type = paramMap.get('type');
			this.siteCode = paramMap.get('siteCode');
			this.psaNumber = paramMap.get('psaNumber');
		});

		if (this.type === 'PsaAndSite') {
			this.viewPartDetails(this.psaNumber,);
			this.psaAndSite = true;
			this.siteRiskService.retrieveSRA(this.psaNumber, this.siteCode).subscribe(data => {
				this.psaSiteDetails = data;
				this.reAssignRequest = data;
				for (let entry of this.psaSiteDetails) {
					this.currentBuyer = entry.buyer;
				}
				this.spinnerFlag = false;
				}, error => {
				this.utilService.pushMsg('error', 'Error', this.errorMsg);
			});
		} else {
			this.printSite = null;
			this.psa = true;
			this.siteRiskService.retrieveSRAForPSANo(this.siteCodeOrPSA).subscribe(data => {
				this.commentsSRA = data;
				this.siteDetails = this.commentsSRA.commentsList;
				//if(this.siteDetails !=null) {
					for (let psa of this.siteDetails) {
						this.printPsa = psa.psaNumber;
					}
					this.spinnerFlag = false;
				/*} else{
					console.log("No Completed Records for this PSA:"+this.siteCodeOrPSA);
					this.utilService.pushMsg('warn', 'Warning', 'No completed records associated with the provided PSA Number '+this.siteCodeOrPSA);
				}*/
				}, error => {
				this.utilService.pushMsg('error', 'Error', this.errorMsg);
			});
		}
	}

	comparativeSummaryByPSA(psa: string) {
		this.siteRiskService.retrieveSRAForPSANo(psa).subscribe(data => {
			console.log(data.isRecordComplete);
			if(data.isRecordComplete !=null && data.isRecordComplete == 'NoCompletedSRA'){
				console.log("No Completed Records for this PSA:"+this.siteCodeOrPSA);
				this.messageService.add({key: 'tl', severity:'info', summary: 'Information', detail: 'No completed records to compare for PSA# '+psa});
				//this.utilService.pushMsg('info', 'Information', 'No completed records to compare for PSA# '+psa);
			}else {
				this.router.navigate(['viewesra', psa, 'PSA']);
			}
		});
		//this.router.navigate(['viewesra', psa, 'PSA']);
	}

	viewSRAForSiteAndPSA(psa: string, site: string) {
		this.router.navigate(['viewesra', psa, site, 'PsaAndSite']);
	}

	onLoadBGColor(colorCode) {
		let diffColor = 'white';
		if (colorCode === 'G' || colorCode === 'GREEN') {
			this.textColor = 'white';
			diffColor = 'green';
		} else if (colorCode === 'Y' || colorCode === 'YELLOW') {
			this.textColor = 'black';
			diffColor = 'yellow';
		} else if (colorCode === 'R' || colorCode === 'RED') {
			this.textColor = 'white';
			diffColor = 'red';
		} else if (colorCode === 'B' || colorCode === 'Not Available' || colorCode === 'BLUE') {
			this.textColor = 'white';
			diffColor = 'blue';
		} else {
			diffColor = 'white';
		}
		return diffColor;
	}

	onLoadOverallTextColor(colorCode) {
		let diffColor = 'white';
		if (colorCode === 'GREEN' || colorCode === 'G') {
			this.overallTextColor = 'white';
			diffColor = 'green';
		} else if (colorCode === 'YELLOW' || colorCode === 'Y') {
			this.overallTextColor = 'black';
			diffColor = 'yellow';
		} else if (colorCode === 'RED' || colorCode === 'R') {
			this.overallTextColor = 'white';
			diffColor = 'red';
		} else if (colorCode === 'BLUE' || colorCode === 'B') {
			this.overallTextColor = 'white';
			diffColor = 'blue';
		} else {
			diffColor = 'white';
		}
		return diffColor;
	}

	qualityCommentsColExp() {
		this.qltyColExp  = !this.qltyColExp;
		this.qltyCollpaseLable = this.qltyColExp ? '-' : '+';
	}
	capCommentsColExp() {
		this.capColExp  = !this.capColExp;
		this.capCollpaseLable = this.capColExp ? '-' : '+';
	}
	SupCommentsColExp() {
		this.supColExp  = !this.supColExp;
		this.supCollpaseLable = this.supColExp ? '-' : '+';
	}
	proCommentsColExp() {
		this.proColExp  = !this.proColExp;
		this.proCollpaseLable = this.proColExp ? '-' : '+';
	}
	// Mit plan
	qualityMitPlanColExp() {
		this.qltyMitColExp  = !this.qltyMitColExp;
		this.qltyMitCollpaseLable = this.qltyMitColExp ? '-' : '+';
	}
	capMitPlanColExp() {
		this.capMitColExp  = !this.capMitColExp;
		this.capMitCollpaseLable = this.capMitColExp ? '-' : '+';
	}
	SupMitPlanColExp() {
		this.supMitColExp  = !this.supMitColExp;
		this.supMitCollpaseLable = this.supMitColExp ? '-' : '+';
	}
	proMitPlanColExp() {
		this.proMitColExp  = !this.proMitColExp;
		this.proMitCollpaseLable = this.proMitColExp ? '-' : '+';
	}

	reOpenSRA(reOpenTest: string, psaNumber: string, siteCode: string, buyer: string, staEng: string) {
		if (reOpenTest == null || reOpenTest === '') {
			this.reopen = 'Reason is required to reopen';
		} else {
			this.reopen = '';
			if (event.defaultPrevented) { return; }
			event.preventDefault();
			this.confirmationService.confirm({
			key: 'confirm-reopen-eSRA',
			message: 'Are you sure you would like to request reopening of this eSRA?',
			reject: () => { this._reopenNo(); },
			accept: () => { this.reopenYes(reOpenTest, psaNumber, siteCode, buyer,  staEng); },
			});
		}
	}
	private _reopenNo() {
		console.log('eSRA Reopen dropped');
	}
	private reopenYes(reOpenTest: string, psaNumber: string, siteCode: string, buyer: string, staEng: string) {
		this.siteRiskService.reOpenSRA(reOpenTest, psaNumber, siteCode, buyer, staEng).subscribe(data => {
			console.log("reopenYes::"+data);
			if (data === 1) {
				console.log("reopen success::"+data);
				this.utilService.pushMsg('success', 'Success', 'eSRA request has been successfully sent to Admin to reopen.');
				this.router.navigate(['home']);
			} else {
				console.log("reopen failure::"+data);
				this.utilService.pushMsg('error', 'Error', 'Unable to reopen eSRA , Please try again later');
			}
		});
	}
	onPrint(psaNumber: string, siteCode: string) {
		const windowSize = 'width=' + (window.innerWidth - 100) + ',height=' + (window.innerHeight + 500);
		if (siteCode != null && psaNumber != null) {
			window.open(window.location.origin + '/#/eprint/' + psaNumber + '/' + siteCode, 'popup', windowSize);
		} else if (siteCode == null  && psaNumber != null) {
			window.open(window.location.origin + '/#/eprintByPSA/' + psaNumber, 'popup', windowSize);
		} else {
			window.open(window.location.origin + '/#/eprintBySite/' + siteCode + '', 'popup', windowSize);
		}
	}

	viewPartDetails(psa: string) {
		this.siteService.getPartDetails(psa).subscribe(parts => {
			if(parts.status !== null){
				this.partStatus = parts.status;
			}else{
				this.partStatus == null;
				this.partDetails = new Array(parts.swtParts.length);
				for (let i = 0; i < parts.swtParts.length; i++) {
					this.partDetails[i] = new GetPartData(parts.swtParts[i]);
				}
			}
		});
	}

	private dateTest(value: string) {
		return value + ' GMT-4';
	}


	onReassign() {
		this.reassign = false;
		this.displayModal = true;
		this.reassignBuyer= null;
		this.errorMsgBuyer = null;
	}

	reassignYes() {
		this.reassign = true;
	}

	reassignToNewBuyer() {
		this.siteRiskService.reassignBuyer(this.reassignBuyer).subscribe(reassignBuyer => {
			if(reassignBuyer !== null){
				this.displayModal = false;
				this.reAssignRequest[0].buyer = this.reassignBuyer;
				this.siteRiskService.updateReassignBuyer(this.reAssignRequest[0],this.userID).subscribe(updateReassignedBuyer => {
					if(updateReassignedBuyer ==1){
						this.utilService.pushMsg('success', 'Success', 'Buyer has been re-assigned successfully.');
						this.reassignBuyer= null;
						this.router.navigate(['home']);
					}
				});
			}else{
				this.displayModal = true;
				this.errorMsgBuyer = "Enter Valid Buyer CDS ID"
			}
		}, error => {
			this.utilService.pushMsg('error', 'Error', 'Unable to reassign.');
		});
	}

	onExit() {
		this.router.navigate(['home']);
	}
}

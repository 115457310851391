import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

/** This class is a service to handle holding and distributing user ID to be displayed
 */
@Injectable({
	providedIn: 'root'
})
export class UserIdService {

	/** The User ID to be stored from authentication and distributed to other components in the app */
	userID:   BehaviorSubject<string> = new BehaviorSubject<string>('notloggedin');

	userName: BehaviorSubject<string> = new BehaviorSubject<string>('User Name Not Available');
	employeeType: BehaviorSubject<string> = new BehaviorSubject<string>('EmployeeType Not Available');
	companyName: BehaviorSubject<string> = new BehaviorSubject<string>('Company Name Not Available');
	companyCode: BehaviorSubject<string> = new BehaviorSubject<string>('Company Code Not Available');
	//role: BehaviorSubject<string> = new BehaviorSubject<string>('Role Not Available');
	role: BehaviorSubject<string> = new BehaviorSubject<string>('eSRA_Buyer');
	mail:  BehaviorSubject<string> = new BehaviorSubject<string>('Mail Not Available');

	// below is for testing
	/*userID:   BehaviorSubject<string> = new BehaviorSubject<string>('RBHASKA8');
	userName: BehaviorSubject<string> = new BehaviorSubject<string>('Bhaskar,Reddy');
	employeeType: BehaviorSubject<string> = new BehaviorSubject<string>('A');
	companyName: BehaviorSubject<string> = new BehaviorSubject<string>('Thirdware Consulting');
	companyCode: BehaviorSubject<string> = new BehaviorSubject<string>('1669825440');
	role: BehaviorSubject<string> = new BehaviorSubject<string>('eSRA_Buyer');
	mail:  BehaviorSubject<string> = new BehaviorSubject<string>('rbhaska8@ford.com');*/

constructor() {
	console.log('Calling UseridService ts');
	if (sessionStorage.getItem('userId')) {
		this.setUserID(sessionStorage.getItem('userId'));
	}
	if (sessionStorage.getItem('userName')) {
		this.setUserName(sessionStorage.getItem('userName'));
	}
	if (sessionStorage.getItem('employeeType')) {
		this.setEmployeeType(sessionStorage.getItem('employeeType'));
	}
	if (sessionStorage.getItem('companyName')) {
		this.setCompanyName(sessionStorage.getItem('companyName'));
	}
	if (sessionStorage.getItem('companyCode')) {
		this.setCompanyCode(sessionStorage.getItem('companyCode'));
	}
	if (sessionStorage.getItem('navUserRole')) {
		this.setRole(sessionStorage.getItem('navUserRole'));
	}
	if (sessionStorage.getItem('domainName')) {
		this.setMail(sessionStorage.getItem('domainName'));
	}
}

		setMail(mail: string) {
			this.mail.next(mail);
		}
		getMail(): Observable<string> {
			return this.mail;
		}

		setRole(role: string) {
			this.role.next(role);
		}
		getRole(): Observable<string> {
			return this.role;
		}

		setUserID(userId: string) {
			this.userID.next(userId);
		}
		getUserID(): Observable<string> {
			return this.userID;
		}

		setUserName(userName: string) {
			this.userName.next(userName);
		}
		getUserName(): Observable<string> {
			return this.userName;
		}

		setEmployeeType(employeeType: string) {
			this.employeeType.next(employeeType);
		}
		getEmployeeType(): Observable<string> {
			return this.employeeType;
		}

		setCompanyName(companyName: string) {
			this.companyName.next(companyName);
		}
		getCompanyName(): Observable<string> {
			return this.companyName;
		}

		setCompanyCode(companyCode: string) {
			this.companyCode.next(companyCode);
		}
		getCompanyCode(): Observable<string> {
			return this.companyCode;
		}

}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {AppComponent} from './app.component';
import {NavComponent} from './nav/nav.component';
import {ButtonModule} from 'primeng/button';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {SidebarModule} from 'primeng/sidebar';
import {DialogService,MessageService} from 'primeng/api';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import {RadioButtonModule} from 'primeng/radiobutton';
import {AppRoutingModule} from './app-routing.module';
import {ExamplePageComponent} from './example-page/example-page.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule,HTTP_INTERCEPTORS,} from '@angular/common/http';
import {HttpRequestInterceptor} from './services/http-request-interceptor.service';
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {PaginatorModule} from 'primeng/paginator';
import {TabViewModule} from 'primeng/tabview';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {TableModule} from 'primeng/table';
import {CalendarModule} from 'primeng/calendar';
import {DatePipe} from '@angular/common';
import {StepsModule} from 'primeng/steps';
import {OauthModule} from './oauth/oauth.module';
import {ExcelService} from './services/exportExcel.service';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService } from 'primeng/api';
import {TooltipModule} from 'primeng/tooltip';
import {AccordionModule} from 'primeng/accordion';
import {EsraPrintComponent} from './esra-print/esra-print.component';
import {ViewesraComponent} from './home/viewesra/viewesra.component';
import {UpdateesraComponent} from './home/updateesra/updateesra.component';
import {HomeComponent} from './home/home.component';
import {CreateesraComponent} from './home/createesra/createesra.component';
import {HelpComponent}  from './help/help.component';
import {CopyesraComponent} from './home/copyesra/copyesra.component';
import {NoAccessComponent} from './no-access/no-access.component';
import {SitedetailsService} from './services/sitedetails.service';
import {SiteriskdetailsService} from './services/siteriskdetails.service';
import {BulletinsService} from './services/bulletins/bulletins.service';
import {CreateBulletinsComponent} from './bulletin/create-bulletins/create-bulletins.component';
import {ManageBulletinsComponent} from './bulletin/manage-bulletins/manage-bulletins.component';
import {ShowBulletinsDialogComponent} from './bulletin/show-bulletins-dialog/show-bulletins-dialog.component';
import {DialogModule} from 'primeng/dialog';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import { OverlayModule} from '@angular/cdk/overlay';
import { PanelModule } from 'primeng/panel';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { AutoFocusDirective } from './auto-focus.directive';;
import { ReportsComponent } from './reports/reports.component'
import {AutoCompleteModule} from 'primeng/autocomplete';
import {MultiSelectModule} from 'primeng/multiselect';
import {ProgressBarModule} from 'primeng/progressbar';

;

@NgModule({
	declarations: [AppComponent,
					NavComponent,
					ExamplePageComponent,
					HomeComponent,
					CreateesraComponent,
					ViewesraComponent,
					AutoFocusDirective,
					UpdateesraComponent,
					HelpComponent,
					CopyesraComponent,
					NoAccessComponent,
					EsraPrintComponent,
					ShowBulletinsDialogComponent,
					ManageBulletinsComponent,
					CreateBulletinsComponent,
					ReportsComponent
					],
	imports: [BrowserModule, AccordionModule, ProgressSpinnerModule,ProgressBarModule,
		StepsModule, DialogModule, DynamicDialogModule, OverlayModule, PanelModule, OverlayPanelModule,
		BrowserAnimationsModule,
		DropdownModule,
		RouterModule,
		AppRoutingModule,
		ButtonModule,
		MenubarModule,
		PanelMenuModule,
		// GrowlModule,
		SidebarModule,
		FormsModule,
		ReactiveFormsModule,
		CheckboxModule,
		RadioButtonModule,
		InputTextareaModule,
		CalendarModule,
		HttpClientModule,
		FieldsetModule,
		TableModule,
		PaginatorModule,
		TabViewModule,
		MessagesModule,
		MessageModule,
		ToastModule,
		CodeHighlighterModule,
		InputTextModule,
		OauthModule, ConfirmDialogModule, TooltipModule, AutoCompleteModule, MultiSelectModule
	],
	entryComponents: [],
	providers: [MessageService,DialogService,SitedetailsService,SiteriskdetailsService,BulletinsService,DatePipe,StepsModule,ExcelService,ConfirmationService,{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpRequestInterceptor,
		multi: true
	}],
	bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SiteriskdetailsService {
  httpOptions = {
    headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json'
    })
    };
  constructor(private http: HttpClient) { }

  //private apiURL = environment.envBaseURL+'/siteCodeDetails/';
	private apiURL = environment.envBaseURL;

	public getCompDomainName(userID): Observable<any> {
		return this.http.get(this.apiURL+'getCompDomainName/'+userID);
	}

	public getLoggedInUserSRAs(userID,role): Observable<any> {
    	return this.http.get(this.apiURL+'getLoggedInUserSRAs/'+userID+'/'+role);
  	}
	public sraForReopen(paramPSA,paramSite): Observable<any> {
		return this.http.get(this.apiURL+'getReOpenSRA/'+paramPSA+'/'+paramSite);
	}

  	public getAllSRAs(psaText,siteText,modelYearText,commodityText,keyDropdown,esfDropdown,planDateText,forecastText,pmtDropdown,
						buyerText,vhmText,staText,statusDropdown,role,userID,domainName,employeeType,companyName,pageNumber,recordsForPage): Observable<any> {
      return this.http.post(this.apiURL+'allSRAs',{psaText,siteText,modelYearText,commodityText,keyDropdown,esfDropdown,planDateText,forecastText,pmtDropdown,
		  						buyerText,vhmText,staText,statusDropdown,role,userID,domainName,employeeType,companyName,pageNumber,recordsForPage},this.httpOptions);
  	}

  	public createSRA(createRequest,companyName): Observable<any> {
    	return this.http.put<any>(this.apiURL+"createSRA?CompanyName="+companyName,createRequest);
  	}
	//return this.http.put<any>(this.apiURL+"updateSRA?Role="+role+'&'+"RecComplete="+SaveOrComplete,editRequest);
  	public updateSRA(editRequest,role,SaveOrComplete): Observable<any> {
   	return this.http.put<any>(this.apiURL+"updateSRA?Role="+role+'&'+"RecComplete="+SaveOrComplete,editRequest);
  	}

  	public delete(deleteRequest,reason): Observable<any> {
   // return this.http.delete<any>(this.apiURL+"deleteSRA?Reason="+reason,deleteRequest);
   		return this.http.put<any>(this.apiURL+"deleteSRA?Reason="+reason,deleteRequest);
  	}

  public updateSiteDetails(siteCode,psaNumber,role): Observable<any> {
    return this.http.get(this.apiURL+'getDataForUpdate/'+siteCode+'/'+psaNumber+'/'+role);
  }

  public isRecordAvailable(siteCode,psaNumber): Observable<any> {
    return this.http.get(this.apiURL+"isRecordExists/"+siteCode+'/'+psaNumber);
  }

  public retrieveSRAForSiteCode(siteCode): Observable<any> {
    return this.http.get(this.apiURL+"retrieveSRABySite/"+siteCode);
  }

  public retrieveSRAForPSANo(psaNumber): Observable<any> {
    return this.http.get(this.apiURL+"retrieveSRAByPSA/"+psaNumber);
  }

  public retrieveSRA(psaNumber,siteCode): Observable<any> {
    return this.http.get(this.apiURL+"retrieveSRA/"+psaNumber+'/'+siteCode);
  }

  public reOpenSRA(reOpenTest,psaNumber,siteCode,buyer,staEng): Observable<any> {
    return this.http.post<any>(this.apiURL+'reOpenSRA1', {reOpenTest,psaNumber,siteCode,buyer,staEng},this.httpOptions);
  }

  public unlockSRA(unlockReopenRquest): Observable<any> {
    return this.http.put<any>(this.apiURL+'unlockSRA',unlockReopenRquest);
  }

  public copyToCreate(copyToCreateRequest): Observable<any> {
    return this.http.put<any>(this.apiURL+"copyToCreate",copyToCreateRequest);
  }

  public reassignBuyer(cdsId): Observable<any> {
	return this.http.get(this.apiURL+'getReassignBuyerID/'+cdsId);
  }

	public updateReassignBuyer(reassignRequest,loggedUser): Observable<any> {
		return this.http.put<any>(this.apiURL+"updateReassignBuyer?LastUpdatedUser="+loggedUser,reassignRequest);
	}

	public updateReassignSite(sraData,reassignSite,keepClear,isCurrentSiteInSIM,isReassignSiteInSIM): Observable<any> {
		return this.http.put<any>(this.apiURL+"updateReassignSite?ReassignSite="+reassignSite+'&'+"KeepClear="+keepClear+'&'+"isSIMSite="+isCurrentSiteInSIM+'&'+"isNewSite="+isReassignSiteInSIM,sraData);
	}

	public isValidUsers(notifyUsers): Observable<any> {
		return this.http.get(this.apiURL+'isValidUsers/'+notifyUsers);
	}
}

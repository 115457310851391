import {Injectable} from '@angular/core';
import {AuthGuardService} from './oauth/auth-guard.service';
import {HttpClient} from '@angular/common/http';
import {Config} from './config';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApsService {
	private config = new Config();
	private serviceUrl = `${this.config.url}`;
	private readonly _token;

	//private apiURL = environment.envBaseURL+'/siteCodeDetails/';
	private apiURL = environment.envBaseURL;

	constructor(private authGuardService: AuthGuardService, private http: HttpClient) {
	}

	// Retrieving Only Roles
	getRoles(): Observable<any> {
		console.log("Calling getRoles")
		return this.http.get(this.apiURL+"allRoles",{responseType: 'text'});
	}

	// Retrieving Roles, SiteCodes
	retrieveAttributes(): Observable<any> {
		return this.http.get(this.apiURL+"retrieveAttributes",{responseType: 'text'});
	}
}


import { Component, OnInit , Renderer2} from '@angular/core';
import {SiteriskdetailsService} from '../../services/siteriskdetails.service';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {Message, SelectItem} from 'primeng/api';
import {UpdateeSRA} from '../../models/updateeSRA-details';
import {SitedetailsService} from '../../services/sitedetails.service';
import {UserIdService} from '../../services/user-id.service.service';
import {UtilService} from '../../services/util.service';
import {GetPartData} from '../../models/part-details';
import {CDSLookUpResponse} from '../../models/cdsIdLookUp-response';
@Component({
	selector: 'copyesra',
	templateUrl: './copyesra.component.html',
	styleUrls: ['./copyesra.component.scss']
})
export class CopyesraComponent implements OnInit {
	colors: SelectItem[];
	successMsg: Message[] = [];
	siteDetails: UpdateeSRA[];
	siteRiskDetails: UpdateeSRA;
	message: Message[] = [];
	userID;
	SELECT_COLOR = '---Select Color---';
	EMPTY: string = '';
	NA: string = 'Not Available';
	siteCodeOrPSA: string;
	type: string;
	textColor: string;
	overallTextColor: string;
	psaMsg: string = '';
	errMsg: string = '';
	dbRadioValue: string = '';
	sitecode: string;
	psano: string;
	noCommodityMsg: string;
	errorMsg: string;
	greenOrBrown: string = '';

	copyeSRAData:boolean = false;
	viewListData:boolean = false;
	checkeSRA:boolean = false;
	checked:boolean = true;
	unChecked:boolean = false;
	staAlignedVerified:boolean = true;
	site:boolean = false;
	psa:boolean = false;
	psaverified:boolean = false;
	isSuccess:boolean = false;
	isError: boolean;
	agree:boolean = false;
	noCommodity:boolean = false;
	isRadioSelected:boolean = true;
	spinnerFlag:boolean = true;
	isTextBoxHide:boolean = false;

	partStatus: string;
	partDetails: GetPartData[];
	private cdsLookUpResponse: CDSLookUpResponse[];
	filteredCDSIDs: any[];
	leadSTAId = false;
	NO_MATCH_FOUND: string = "No Match Found, Please try again";
	NOTIFY_USERS ="Enter the CDSID of any additional personnel (separated by a semicolon) that you would like to be included on system-generated emails.<br> By default, all emails automatically include the assigned Buyer, Lead STA, and the Lead STA’s immediate supervisor so these should not be entered here.";

	constructor(private router: Router, private siteRiskService: SiteriskdetailsService,
				private aRouter: ActivatedRoute,
				private userIdService: UserIdService,
				private siteService: SitedetailsService,private utilService: UtilService) {

		this.colors = [
			{label: '---Select Color---', value: '---Select Color---'}  ,
			{label: 'GREEN', value: 'GREEN' },
			{label: 'YELLOW', value: 'YELLOW'},
			{label: 'RED', value: 'RED'},
			{label: 'Not Available', value: 'BLUE'},
		];
	}

	ngOnInit() {
		this.retrieveData();
	}

	private retrieveData() {
		this.aRouter.paramMap.subscribe((paramMap: ParamMap) => {
			this.userIdService.userID.subscribe((value: string) => {
				this.userID = value;
			});
			this.sitecode = paramMap.get('siteCode');
			this.psano = paramMap.get('psaNumber');
			this.spinnerFlag = true;
			this.copyeSRA(this.sitecode, this.psano);
		});
	}
	copyeSRA(siteCode, psaNumber) {
		this.copyeSRAData = true;
		this.viewListData = true;
		this.noCommodity = true;
		this.siteRiskService.updateSiteDetails(siteCode, psaNumber,null).subscribe(data => {
			if(data === null){
				this.utilService.pushMsg('warn', 'Warning', 'Unable to copy SRA, Please contact ESRASUPP@ford.com');
				this.spinnerFlag = false;
				this.router.navigate(['home']);
			} else{
			this.siteRiskDetails = data;
			data.buyer = this.userID;

			this.siteRiskDetails.psaNumber = this.EMPTY;
			this.siteRiskDetails.modelYear = this.EMPTY;
			this.siteRiskDetails.commodityCode = this.EMPTY;
			this.siteRiskDetails.keyCommodity = this.EMPTY;
			this.siteRiskDetails.esfCommodity = this.EMPTY;
			this.siteRiskDetails.pmt = this.EMPTY;
			this.siteRiskDetails.vhm = this.EMPTY;
			this.siteRiskDetails.step2PlanDate = this.EMPTY;
			this.siteRiskDetails.step2ForecastDate = this.EMPTY;
			this.siteRiskDetails.psaVerified = 'false';
			this.siteRiskDetails.staAligned = this.EMPTY;
			this.siteRiskDetails.currentDate = '';
			this.siteRiskDetails.ll6Verified = this.EMPTY;
			this.siteRiskDetails.recordComplete = 'N';
			this.siteRiskDetails.greenORbrown = null;
			this.siteRiskDetails.qualtiyRiskColor = this.EMPTY;
			this.siteRiskDetails.q1Score = this.EMPTY;
			this.siteRiskDetails.q1ScoreColor = this.EMPTY;
			this.siteRiskDetails.q1Status = this.EMPTY;
			this.siteRiskDetails.q1CodeColor = this.EMPTY;
			this.siteRiskDetails.ppm = this.EMPTY;
			this.siteRiskDetails.ppmColor = this.EMPTY;
			this.siteRiskDetails.openwrntySpikes = this.EMPTY;
			this.siteRiskDetails.openwrntySpikesColor = this.EMPTY;
			this.siteRiskDetails.stopshipments = this.EMPTY;
			this.siteRiskDetails.stopshipmentsColor = this.EMPTY;
			this.siteRiskDetails.openFSA = this.EMPTY;
			this.siteRiskDetails.openFSAColor = this.EMPTY;
			this.siteRiskDetails.dlySixMonthsAvg = this.EMPTY;
			this.siteRiskDetails.dlySixMonthsAvgColor = this.EMPTY;
			this.siteRiskDetails.priorLaunchPrf = this.EMPTY;
			this.siteRiskDetails.priorLaunchPrfColor = this.EMPTY;
			this.siteRiskDetails.qltySysQ1MSA = this.EMPTY;
			this.siteRiskDetails.qltySysQ1MSAColor = this.EMPTY;
			this.resetQualityCommentsAndMitPlan();
			this.spinnerFlag = false;
		}
	});
	}
	onKey(psa: string) {
		if (psa.length > 0 && psa.match('^[0-9]*$')) {
			this.viewPartDetails(psa)
			this.psaMsg = '';
			this.siteService.getPSADetails(psa).subscribe(data => {
				if (data != null) {
					this.psaMsg = '';
					this.noCommodity = false;
					this.siteRiskDetails.psaNumber = data.psaNumber;
					this.siteRiskDetails.modelYear =  data.modelYear;
					this.siteRiskDetails.commodityCode = data.commodityCode;
					this.siteRiskDetails.keyCommodity = data.keyCommodity;
					this.siteRiskDetails.esfCommodity = data.esfCommodity;
					this.siteRiskDetails.pmt = data.pmt;
					this.siteRiskDetails.vhm = data.vhm;
					if (data.step2PlanDate != null) {
						this.siteRiskDetails.step2PlanDate =  this.convertDate(data.step2PlanDate);
					} else {
						this.siteRiskDetails.step2PlanDate = data.step2PlanDate;
					}
					if (data.step2ForecastDate != null) {
						this.siteRiskDetails.step2ForecastDate =  this.convertDate(data.step2ForecastDate);
					} else {
						this.siteRiskDetails.step2ForecastDate = data.step2ForecastDate;
					}
					if (this.siteRiskDetails.commodityCode == null || this.siteRiskDetails.commodityCode === '') {
						this.noCommodity = true;
						this.noCommodityMsg = 'Commodity is missing.  Please exit the system and update commodity information' +
							' in SWT before creating an eSRA for this PSA.';
					} else {
						this.noCommodity = false;
					}
				} else {
					this.noCommodityMsg = '';
					this.psaMsg = 'Enter Valid PSA Number';
				}
			});
		} else {
			this.noCommodityMsg = '';
			if (!psa.match('^[0-9]*$')) {
				this.psaMsg = 'PSA Number allows only numeric values';
			} else {
				this.noCommodityMsg = '';
				this.psaMsg = 'PSA Number is required';
			}
		}
	}
	keyPressAlphaNumeric(event) {
		let valCheck;
		valCheck = String.fromCharCode(event.keyCode);
		if (/[a-zA-Z0-9; ]/.test(valCheck)) {
			this.errorMsg = '';
			return true;
		} else {
			event.preventDefault();
			this.errorMsg = 'Allows only AlphaNumeric Values and Semicolon';
			return false;
		}
	}

	checkBtn(value: string) {
		if (!value) {
			this.isTextBoxHide = true;
		} else {
			this.isTextBoxHide = false;
		}
	}
	copyForcreateeSRA(site, psa) {
		this.successMsg = [];
		if ((this.siteRiskDetails.notifyUsersFlag) && (this.siteRiskDetails.notifyAdditionalUsers == null
			|| this.siteRiskDetails.notifyAdditionalUsers === '')) {
			this.errorMsg = 'Must be enter valid cds id(s).';
		} else {
			if (this.siteRiskDetails.greenORbrown == null) {
				this.greenOrBrown = 'Greenfield / Brownfield must be selected before sending to STA';
			} else {
				if (this.agree) {
					this.isSuccess = true;
					this.siteRiskService.isRecordAvailable(site, psa).subscribe(data => {
						this.siteDetails = data;
						if (data != null) {
							if ((site =  data.siteCode) && (psa = data.psaNumber)) {
								this.checkeSRA = true;
								this.utilService.pushMsg('warn', 'Warning', 'eSRA for this Supplier/PSA combination already exists.');
							}
						} else {
							this.siteRiskDetails.psaVerified = 'true';
							this.siteRiskService.copyToCreate(this.siteRiskDetails).subscribe(data => {
								this.siteRiskDetails = data;
								if (data != null) {
									this.utilService.pushMsg('success', 'Success', 'eSRA request has been successfully sent to STA for review.');
									this.router.navigate(['home']);
								} else {
									this.utilService.pushMsg('error', 'Error', 'Enter Valid PSA Number');
								}
								}, error => {
								if (error.status === 404) {
									this.utilService.pushMsg('error', 'Error', 'Enter Valid STA Site Engineer / Global Core CDS ID');
								} else if (error.status === 406) {
									this.utilService.pushMsg('error', 'Error', 'Enter Valid CDSID(s) for Notified Additional Users');
								} else {
									this.utilService.pushMsg('error', 'Error', 'Unable to copy eSRA , Please try again later.');
								}
							});
							this.checkeSRA = false;
						}});
				} else {
					this.isError = false;
					this.isSuccess = true;
					this.errMsg = 'PSA details must be verified before sending to STA';
				}
			}
		}
	}
	changeRadioValue(selectedRadio: string, simOrGSDB: string) {
		this.dbRadioValue = selectedRadio;
		if (selectedRadio === 'NO' && simOrGSDB === 'Y') {
			this.siteService.getSIMSiteData(this.sitecode).subscribe(data => {
				this.isRadioSelected = true;
				if (data != null) {
					this.siteDetails = data;
					data.buyer = this.userID;
					if (data.qualtiyRiskColor === 'B') {
						this.siteRiskDetails.qualtiyRiskColor = 'BLUE';
					}
					if (data.qualtiyRiskColor === 'R') {
						this.siteRiskDetails.qualtiyRiskColor = 'RED';
					}
					if (data.qualtiyRiskColor === 'Y') {
						this.siteRiskDetails.qualtiyRiskColor = 'YELLOW';
					}
					if (data.qualtiyRiskColor === 'G') {
						this.siteRiskDetails.qualtiyRiskColor = 'GREEN';
					}
					if (data.q1Score == null) {
						this.siteRiskDetails.q1Score = this.NA;
					} else {
						this.siteRiskDetails.q1Score = data.q1Score + ' / 100';
					}
					if (data.q1Status == null) {
						this.siteRiskDetails.q1Status = this.NA;
					} else {
						this.siteRiskDetails.q1Status = data.q1Status;
					}
					if (data.ppm == null) {
						this.siteRiskDetails.ppm = this.NA;
					} else {
						this.siteRiskDetails.ppm = data.ppm + ' / 10';
					}
					if (data.openwrntySpikes == null) {
						this.siteRiskDetails.openwrntySpikes = this.NA;
					} else {
						this.siteRiskDetails.openwrntySpikes = data.openwrntySpikes + ' / 5';
					}
					if (data.stopshipments == null) {
						this.siteRiskDetails.stopshipments = this.NA;
					} else {
						this.siteRiskDetails.stopshipments = data.stopshipments + ' / 20';
					}
					if (data.openFSA == null) {
						this.siteRiskDetails.openFSA = this.NA;
					} else {
						this.siteRiskDetails.openFSA = data.openFSA + ' / 15';
					}
					if (data.dlySixMonthsAvg == null) {
						this.siteRiskDetails.dlySixMonthsAvg = this.NA;
					} else {
						this.siteRiskDetails.dlySixMonthsAvg = data.dlySixMonthsAvg + ' / 10';
					}
					if (data.priorLaunchPrf == null) {
						this.siteRiskDetails.priorLaunchPrf = this.NA;
					} else {
						this.siteRiskDetails.priorLaunchPrf = data.priorLaunchPrf + ' / 5';
					}
					if (data.qltySysQ1MSA == null) {
						this.siteRiskDetails.qltySysQ1MSA = this.NA;
					} else {
						this.siteRiskDetails.qltySysQ1MSA = data.qltySysQ1MSA + ' / 15';
					}
					this.siteRiskDetails.q1ScoreColor = data.q1ScoreColor;
					this.siteRiskDetails.q1CodeColor = data.q1CodeColor;
					this.siteRiskDetails.ppmColor = data.ppmColor;
					this.siteRiskDetails.openwrntySpikesColor = data.openwrntySpikesColor;
					this.siteRiskDetails.openFSAColor = data.openFSAColor;
					this.siteRiskDetails.dlySixMonthsAvgColor = data.dlySixMonthsAvgColor;
					this.siteRiskDetails.priorLaunchPrfColor = data.priorLaunchPrfColor;
					this.siteRiskDetails.stopshipmentsColor = data.stopshipmentsColor;
					this.siteRiskDetails.qltySysQ1MSAColor = data.qltySysQ1MSAColor;
					this.resetQualityCommentsAndMitPlan();
				}});
		} else {
			if (this.isRadioSelected) {
				this.isRadioSelected = false;
				this.siteRiskDetails.qualtiyRiskColor = null;
				this.siteRiskDetails.q1ScoreColor = this.NA;
				this.siteRiskDetails.q1CodeColor = this.SELECT_COLOR;
				this.siteRiskDetails.ppmColor =  this.SELECT_COLOR;
				this.siteRiskDetails.openwrntySpikesColor =  this.SELECT_COLOR;
				this.siteRiskDetails.openFSAColor =  this.SELECT_COLOR;
				this.siteRiskDetails.stopshipmentsColor =  this.SELECT_COLOR;
				this.siteRiskDetails.dlySixMonthsAvgColor =  this.SELECT_COLOR;
				this.siteRiskDetails.priorLaunchPrfColor =  this.SELECT_COLOR;
				this.siteRiskDetails.qltySysQ1MSAColor =  this.SELECT_COLOR;
			}
		}
	}
	resetQualityCommentsAndMitPlan() {
		this.siteRiskDetails.q1ScoreComments = this.EMPTY;
		this.siteRiskDetails.q1ScoreMitPlan = this.EMPTY;
		this.siteRiskDetails.q1ScoreOwner = this.EMPTY;
		this.siteRiskDetails.q1ScoreTgtDate = this.EMPTY;
		this.siteRiskDetails.q1StatusComments = this.EMPTY;
		this.siteRiskDetails.q1StatusMitPlan = this.EMPTY;
		this.siteRiskDetails.q1StatusOwner = this.EMPTY;
		this.siteRiskDetails.q1StatusTgtDate = this.EMPTY;
		this.siteRiskDetails.ppmComments = this.EMPTY;
		this.siteRiskDetails.ppmMitPlan = this.EMPTY;
		this.siteRiskDetails.ppmOwner = this.EMPTY;
		this.siteRiskDetails.ppmTgtDate = this.EMPTY;
		this.siteRiskDetails.warrantyComments = this.EMPTY;
		this.siteRiskDetails.warrantyMitPlan = this.EMPTY;
		this.siteRiskDetails.warrantyOwner = this.EMPTY;
		this.siteRiskDetails.warrantyTgtDate = this.EMPTY;
		this.siteRiskDetails.stopShipComments = this.EMPTY;
		this.siteRiskDetails.stopShipMitPlan = this.EMPTY;
		this.siteRiskDetails.stopShipOwner = this.EMPTY;
		this.siteRiskDetails.stopShipTgtDate = this.EMPTY;
		this.siteRiskDetails.fsaComments = this.EMPTY;
		this.siteRiskDetails.fsaMitPlan = this.EMPTY;
		this.siteRiskDetails.fsaOwner = this.EMPTY;
		this.siteRiskDetails.fsaTgtDate = this.EMPTY;
		this.siteRiskDetails.deliveryComments = this.EMPTY;
		this.siteRiskDetails.deliveryMitPlan = this.EMPTY;
		this.siteRiskDetails.deliveryOwner = this.EMPTY;
		this.siteRiskDetails.deliveryTgtDate = this.EMPTY;
		this.siteRiskDetails.priorLaunchComments = this.EMPTY;
		this.siteRiskDetails.priorLaunchMitPlan = this.EMPTY;
		this.siteRiskDetails.priorLaunchOwner = this.EMPTY;
		this.siteRiskDetails.priorLaunchTgtDate = this.EMPTY;
		this.siteRiskDetails.q1MSAComments = this.EMPTY;
		this.siteRiskDetails.q1MSAMitPlan = this.EMPTY;
		this.siteRiskDetails.q1MSAOwner = this.EMPTY;
		this.siteRiskDetails.q1MSATgtDate = this.EMPTY;
	}
	convertDate(longDate) {
		const radix = 10;
		const date = new Date(parseInt(longDate, radix));
		return this.generateUTCDate( date.getUTCDate(), date.getUTCMonth(), date.getUTCFullYear());
	}
	private generateUTCDate1( day, month, year) {
		const actualMonth = Number(month) + 1;
		return `${day.toString()}/${actualMonth.toString()}/${year.toString()}`;
	}
	private generateUTCDate( day, month, year) {
		const actualMonth = Number(month) + 1;
		let monthFormat;
		if (actualMonth != null) {
			if (actualMonth.toString() === '01' || actualMonth.toString() === '1') {
				monthFormat = 'Jan';
			} else if (actualMonth.toString() === '02' || actualMonth.toString() === '2') {
				monthFormat = 'Feb';
			} else if (actualMonth.toString() === '03' || actualMonth.toString() === '3') {
				monthFormat = 'Mar';
			} else if (actualMonth.toString() === '04' || actualMonth.toString() === '4') {
				monthFormat = 'Apr';
			} else if (actualMonth.toString() === '05' || actualMonth.toString() === '5') {
				monthFormat = 'May';
			} else if (actualMonth.toString() === '06' || actualMonth.toString() === '6') {
				monthFormat = 'Jun';
			} else if (actualMonth.toString() === '07' || actualMonth.toString() === '7') {
				monthFormat = 'Jul';
			} else if (actualMonth.toString() === '08' || actualMonth.toString() === '8') {
				monthFormat = 'Aug';
			} else if (actualMonth.toString() === '09' || actualMonth.toString() === '9') {
				monthFormat = 'Sep';
			} else if (actualMonth.toString() === '10') {
				monthFormat = 'Oct';
			} else if (actualMonth.toString() === '11') {
				monthFormat = 'Nov';
			} else if (actualMonth.toString() === '12') {
				monthFormat = 'Dec';
			}
		}
		return `${day.toString()}-${monthFormat.toString()}-${year.toString()}`;
	}
	onLoadBGColor(colorCode) {
		let diffColor = 'white';
		if (colorCode === 'G' || colorCode === 'GREEN') {
			this.textColor = 'white';
			diffColor = 'green';
		} else if (colorCode === 'Y' || colorCode === 'YELLOW') {
			this.textColor = 'black';
			diffColor = 'yellow';
		} else if (colorCode === 'R' || colorCode === 'RED') {
			this.textColor = 'white';
			diffColor = 'red';
		} else if (colorCode === 'B' || colorCode === 'Not Available' || colorCode === 'BLUE') {
			this.textColor = 'white';
			diffColor = 'blue';
		} else {
			diffColor = 'white';
		}
		return diffColor;
	}
	onLoadBlueColor(value) {
		let colorBlue = 'blue';
		if (value === this.NA) {
			colorBlue = 'blue';
		}
		return colorBlue;
	}
	onLoadOverallTextColor(colorCode) {
		let diffColor = 'white';
		if (colorCode === 'GREEN') {
			this.overallTextColor = 'white';
			diffColor = 'green';
		} else if (colorCode === 'YELLOW') {
			this.overallTextColor = 'black';
			diffColor = 'yellow';
		} else if (colorCode === 'RED') {
			this.overallTextColor = 'white';
			diffColor = 'red';
		} else if (colorCode === 'BLUE') {
			this.overallTextColor = 'white';
			diffColor = 'blue';
		} else {
			diffColor = 'white';
		}
		return diffColor;
	}
	changeDropdownBackgroundColor(color) {
		if (color === this.NA) {
			color = 'blue';
		}
		return color;
	}
	changeTextColor(textColor) {
		if (textColor === this.NA || textColor === 'GREEN' || textColor === 'RED') {
			textColor = 'White';
		} else {
			textColor = 'black';
		}
		return textColor;
	}

	viewPartDetails(psa: string) {
		this.siteService.getPartDetails(psa).subscribe(parts => {
			if(parts.status !== null){
				this.partStatus = parts.status;
			}else{
				this.partStatus == null;
				this.partDetails = new Array(parts.swtParts.length);
				for (let i = 0; i < parts.swtParts.length; i++) {
					this.partDetails[i] = new GetPartData(parts.swtParts[i]);
				}
			}
		});
	}

	noMatchFoundValid(value: string, checkValid) {
		//lead STA
		if (value == 'leadSTA') {
			this.leadSTAId = checkValid;
		}
	}
	getCDSId(value: string) {
		if (value == 'leadSTA') {
			this.siteRiskDetails.staEng = this.siteRiskDetails.staEng.split('-')[0].trim();
		}
	}

	searchKeywordForCDSId(event, name: string) {
		let filtered: any[] = [];
		this.cdsLookUpResponse = [];
		const userCDSId = event.query;
		if (userCDSId.length > 2) {
			this.noMatchFoundValid(name, false);
			this.siteService.getCDSIdDetails(userCDSId).subscribe(cdsIDs => {
				this.cdsLookUpResponse = cdsIDs;
				if (this.cdsLookUpResponse !== null) {
					for (let i = 0; i < this.cdsLookUpResponse.length; i++) {
						let cdsId = this.cdsLookUpResponse[i].userId+ '-' +this.cdsLookUpResponse[i].fordDisplayName+ '-' +this.cdsLookUpResponse[i].fordDesignation;
						filtered.push(cdsId);
					}
					this.filteredCDSIDs = filtered;
					filtered = [];
				} else {
					this.noMatchFoundValid(name, true);
					this.filteredCDSIDs = [];
				}
			}, error => {
				console.log(error.status);
				this.noMatchFoundValid(name, true);
			});
		} else {
			this.noMatchFoundValid(name, false);
			this.filteredCDSIDs = [];
			this.cdsLookUpResponse = [];
		}
	}
}

import {Component, HostListener, OnInit} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {SiteriskdetailsService} from '../services/siteriskdetails.service';
import {RetrieveeSRA} from '../models/retrieveeSRA-details';
import {Message} from 'primeng/api';
import {CommentsSRA} from '../models/comments';

@Component({
	selector: 'esra-print',
	templateUrl: './esra-print.component.html',
	styleUrls: ['./esra-print.component.scss']
})
export class EsraPrintComponent implements OnInit {
	constructor(private router: Router, private aRouter: ActivatedRoute,
              private printRequest: SiteriskdetailsService) { }

	siteCode: string;
	psaNumber: string;
	overallTextColor: string;
	textColor: string;
	site: boolean = false;
	psa: boolean = false;

	printStyle = ['print-button', 'ui-button-info'];

	siteOrPSA: boolean = false;
	psaAndSite: boolean = false;

	type: string;

	qualityColExp: boolean = true;
	qualityCollapseLabel: string = '-';

	capColExp: boolean = true;
	capacityCollapseLabel: string = '-';

	supColExp: boolean = true;
	supplierCollapseLabel: string = '-';

	proColExp: boolean = true;
	programCollapseLabel: string = '-';

	//Mit Plan
	qualityMitColExp: boolean = true;
	qualityMitCollapseLabel: string = '-';

	capMitColExp: boolean = true;
	capacityMitCollapseLabel: string = '-';

	supMitColExp: boolean = true;
	supplierMitCollapseLabel: string = '-';

	proMitColExp: boolean = true;
	programMitCollapseLabel: string = '-';

	commentsSRA: CommentsSRA;
	printPsaAndSiteData: RetrieveeSRA[];
	message: Message[] = [];
	errorMsg = 'Unable to Print, Please try again later';

	ngOnInit() {
		this.retrieveData();
	}

	private retrieveData(){
		this.aRouter.paramMap.subscribe((paramMap: ParamMap) => {
			this.siteCode = paramMap.get('siteCode');
			this.psaNumber = paramMap.get('psaNumber');
		});

		if (this.siteCode != null && this.psaNumber != null) {
			this.psaAndSite = true;
			this.printRequest.retrieveSRA(this.psaNumber, this.siteCode).subscribe(data => {
				this.printPsaAndSiteData = data;
				}, error => {
				this.message.push({severity: 'error', summary: 'Error', detail: this.errorMsg});
			});
		}

		if (this.psaNumber != null && this.siteCode == null) {
			this.siteOrPSA = true;
			this.psa = true;
			this.printRequest.retrieveSRAForPSANo(this.psaNumber).subscribe(data => {
				this.commentsSRA = data;
				this.printPsaAndSiteData = this.commentsSRA.commentsList;
				}, error => {
				this.message.push({severity: 'error', summary: 'Error', detail: this.errorMsg});
			});
		}
	}
	onLoadBGColor(colorCode) {
		let diffColor = 'white';
		if (colorCode === 'G' || colorCode === 'GREEN') {
			this.textColor = 'white';
			diffColor = 'green';
		} else if (colorCode === 'Y' || colorCode === 'YELLOW') {
			this.textColor = 'black';
			diffColor = 'yellow';
		} else if (colorCode === 'R' || colorCode === 'RED') {
			this.textColor = 'white';
			diffColor = 'red';
		} else if (colorCode === 'B' || colorCode === 'Not Available' || colorCode === 'BLUE') {
			this.textColor = 'white';
			diffColor = 'blue';
		} else {
			diffColor = 'white';
			this.textColor = 'black';
		}
		return diffColor;
	}

	onLoadOverallTextColor(colorCode) {
		let diffColor = 'white';
		if (colorCode === 'GREEN') {
			this.overallTextColor = 'white';
			diffColor = 'green';
		} else if (colorCode === 'YELLOW') {
			this.overallTextColor = 'black';
			diffColor = 'yellow';
		} else if (colorCode === 'RED') {
			this.overallTextColor = 'white';
			diffColor = 'red';
		} else if (colorCode === 'BLUE') {
			this.overallTextColor = 'white';
			diffColor = 'blue';
		} else {
			diffColor = 'white';
		}
		return diffColor;
	}

	onPrint() {
		const landscape = true;
		const head = document.head || document.getElementsByTagName('head')[0];
		const style = document.createElement('style');
		style.type = 'text/css';
		style.media = 'print';
		style.appendChild(document.createTextNode(landscape ?
			'@page { size: A4 landscape; margin: 0in;}' :
			'@page { size: A4;  margin: 0in; }'));
		head.appendChild(style);
		window.print();
	}

	@HostListener('window:afterprint')
	onAfterPrint() {
		window.close();
	}

	private dateTest(value: string) {
		return value + ' GMT-4';
	}

	qualityCommentsColExp() {
		this.qualityColExp  = !this.qualityColExp;
		this.qualityCollapseLabel = this.qualityColExp ? '-' : '+';
	}
	capCommentsColExp() {
		this.capColExp  = !this.capColExp;
		this.capacityCollapseLabel = this.capColExp ? '-' : '+';
	}

	SupCommentsColExp() {
		this.supColExp  = !this.supColExp;
		this.supplierCollapseLabel = this.supColExp ? '-' : '+';
	}

	proCommentsColExp() {
		this.proColExp  = !this.proColExp;
		this.programCollapseLabel = this.proColExp ? '-' : '+';
	}
	qualityMitPlanColExp() {
		this.qualityMitColExp  = !this.qualityMitColExp;
		this.qualityMitCollapseLabel = this.qualityMitColExp ? '-' : '+';
	}
	capMitPlanColExp() {
		this.capMitColExp  = !this.capMitColExp;
		this.capacityMitCollapseLabel = this.capMitColExp ? '-' : '+';
	}
	SupMitPlanColExp() {
		this.supMitColExp  = !this.supMitColExp;
		this.supplierMitCollapseLabel = this.supMitColExp ? '-' : '+';
	}
	proMitPlanColExp() {
		this.proMitColExp  = !this.proMitColExp;
		this.programMitCollapseLabel = this.proMitColExp ? '-' : '+';
	}
}

import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

/** This class is an implementation of an HttpInterceptor
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	/** This method is required by the fact that this class implements HttpInterceptor.
	 * It accepts (intercepts) HttpRequests and adds the headers required by API's for this application.
	 *
	 * @param request the HttpRequest object
	 * @param next the Http handling object
	 * @returns the Observable representing the next HttpEvent
	 */

	constructor(private router: Router) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		request = request.clone({
			setHeaders: {
				Authorization: 'Bearer ' + sessionStorage.getItem('encodedAccessToken'),
				'Content-Type': 'application/json',
				Y7fQPGwn: '1'
			}
		});
		//console.log('HttpRequestInterceptor intercept called');
		return next.handle(request).pipe(
			tap(evt => {
				if (evt instanceof HttpResponse) {
					if (evt.body && evt.body.success) {
						console.log('success');
					}
				}
			}),
			catchError((error: HttpErrorResponse): Observable<HttpEvent<Object>> => {
				const serverError = 500;
				const badRequest = 400;
				const notFound = 404;
				const postError = 401;
				const statusCodes = [serverError, badRequest, notFound, postError];
				if (statusCodes.indexOf(error.status) === -1) {
					sessionStorage.clear();
					this.router.navigate(['/404']);
				}
				return throwError(error);
			})
		);
	}
}

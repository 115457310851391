import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'help',
	templateUrl: './help.component.html'
	//,	styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
	emailTo = 'mailto:ESRASUPP@ford.com';
	constructor() { }
	ngOnInit() {
	}

}

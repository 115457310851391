import {Component,OnInit,ViewChild} from '@angular/core';
import {Router,	ActivatedRoute,	ParamMap} from '@angular/router';
import {SitedetailsService} from '../services/sitedetails.service';
import { SiteriskdetailsService } from '../services/siteriskdetails.service';
import {CreateeSRA} from '../models/createeSRA-details';
import {GetSimData} from '../models/sim-details';
import {UpdateeSRA} from '../models/updateeSRA-details';
import {GetPSAData} from '../models/psa-details';
import {ApsService} from '../aps.service';
import {UserIdService} from '../services/user-id.service.service';
import {MessageService,ConfirmationService,Message,SelectItem,LazyLoadEvent} from 'primeng/api';

import { Overlay} from '@angular/cdk/overlay';
import {UtilService} from '../services/util.service';
import {Table} from 'primeng/table';
//import {CDSLookUpResponse} from '../models/cdsIdLookUp-response';

@Component({
	selector: 'home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	providers: [MessageService]
})

export class HomeComponent implements OnInit {
	@ViewChild(Table, {static: false})
	dt: Table;
	userID: string;
	employeeType: string;
	companyDomain: string;
	companyName: string;
	success: string;
	psaText: string = '';
	siteText: string = '';
	modelYearText: string = '';
	commodityText: string = '';
	planDateText: string = '';
	forecastText: string = '';
	buyerText: string = '';
	//vhmText: string = '';
	staText: string = '';
	lastUpdatedText: string = '';
	keyDropdown: string = '';
	esfDropdown: string = '';
	pmtDropdown: string = '';
	statusDropdown: string = '';
	vhmDropdown: string = '';

	statusOptions: SelectItem[];
	vhmOptions: SelectItem[];
	//keyCommodityOptions: SelectItem[];
	//esfCommodityOptions: SelectItem[];
	pmtOptions: SelectItem[];

	totalRecords: number;
	first = 0;
	pageNumber: number = 0;

	date = Date;

	reOpenMessage: boolean = false;
	loadScreen: boolean = false;
	confirmation:string="This Site does not have a Production STA assigned in SIM.Are you sure you want to proceed?";
	constructor(private router: Router, private messageService: MessageService, private overlay: Overlay, private aRouter: ActivatedRoute,
				private siteService: SitedetailsService, private siteRiskService: SiteriskdetailsService,
				private apsService: ApsService, private userIdService: UserIdService, private confirmationService: ConfirmationService, private utilService: UtilService) {//private updatesra:UpdateesraComponent
		this.statusOptions = [
			{label: 'In Progress', value: 'N'},
			{label: 'Completed', value: 'Y'},];

		this.vhmOptions = [
			{label: 'DIGITAL TECH', value: 'DIGITAL TECH'},
			{label: 'ELECTRIFIED SYSTEMS', value: 'ELECTRIFIED SYSTEMS'},
			{label: 'EXTERIOR SYSTEMS', value: 'EXTERIOR SYSTEMS'},
			{label: 'ICE AND THERMAL SYST', value: 'ICE AND THERMAL SYST'},
			{label: 'INTERIOR SYSTEMS', value: 'INTERIOR SYSTEMS'},
			/*{label: 'OTHER', value: 'OTHER'},*/
			{label: 'RAW MTL AND STRUCT', value: 'RAW MTL AND STRUCT'},
			{label: 'UNDERBODY SYSTEMS', value: 'UNDERBODY SYSTEMS'},];
		/*this.keyCommodityOptions = [
			{label: 'Key  ', value: 'Key Commodity'},
			{label: 'Non-Key  ', value: 'Non-Key Commodity'},];

		this.esfCommodityOptions = [
			{label: 'ESF', value: 'ESF'},
			{label: 'Non-ESF', value: 'Non-ESF'},];*/
	}

	message: Message[] = [];
	allSRAs: UpdateeSRA[];
	allSRAFromDB: UpdateeSRA[];
	tempSRA: UpdateeSRA[];
	siteRiskDetails: UpdateeSRA;
	siteData: GetSimData;
	psaData: GetPSAData;
	siteDetails: CreateeSRA;

	positionLeft = '240px';
	positionTop = '-320px';
	width = '350px';
	marginStyle = {
		'margin-right': this.positionLeft,
		'margin-top': this.positionTop,
		'width': this.width,
		'background-color': '#AFDCEC'
	};

	siteCode: string;
	psaNumber: string;
	errorMessage: string;
	role: string;
	assignedToMe: string;
	assignedNotToMe: string;
	deleteReason: string = null;

	paramSite: string;
	paramPSA: string;

	tempPsa: string;
	tempSite: string;
	tempAddUsers: string = null;
	tempSTA: string;
	tempBuyer: string;
	//tempCommodity: string;

	isBuyer: boolean = false;
	isSTA: boolean = false;
	isAdmin: boolean = false;
	displayModal: boolean;
	reasonForDelete: boolean = false;
	f = false;
	spinnerFlag = true;
	pmt: string;
	status: string;
	clearKeyDropdown: boolean = false;
	clearESFDropdown: boolean = false;
	clearPMTDropdown: boolean = false;
	clearStatusDropdown: boolean = false;
	clearVHMDropdown: boolean = false;

	currentPageSize = 25;
	simAndGsdb:string;

	ngOnInit() {
		/*get roles*/
		this.aRouter.paramMap.subscribe((paramMap: ParamMap) => {
			this.paramSite = paramMap.get('siteCode');
			this.paramPSA = paramMap.get('psaNumber');
		});

		this.userIdService.role.subscribe((value: string) => {
			this.role = value;
			sessionStorage.setItem("navUserRole", this.role);
			console.log("Role form Session Storage::" + sessionStorage.getItem("navUserRole"));
			this.retrieveRoles();
		});
		/*domain name from mail id*/
		this.userIdService.mail.subscribe((value: string) => {
			this.companyDomain = value;
			if (this.companyDomain.includes("@")) {
				this.companyDomain = this.companyDomain.split('@')[1];
			}
		});

		/*get logged in user id*/
		this.userIdService.userID.subscribe((value: string) => {
			this.userID = value.toUpperCase();
			sessionStorage.setItem("userID", this.userID);
			console.log("UserID form Session Storage::" + sessionStorage.getItem("userID"));
		});

		/*get employee type*/
		this.userIdService.employeeType.subscribe((value: string) => {
			this.employeeType = value.toUpperCase();
			sessionStorage.setItem("employeeType", this.employeeType);
			console.log("Employee Type from Session Storage::" + sessionStorage.getItem("employeeType"));
		});

		/*get Company Name*/
		this.userIdService.companyName.subscribe((value: string) => {
			this.companyName = value;
			sessionStorage.setItem("companyName", this.companyName);
			console.log("Employee Type from Session Storage::" + sessionStorage.getItem("companyName"));
		});
	}
	private retrieveRoles() {
		this.apsService.getRoles().subscribe(roles => {
			if (roles == null || roles === '') {
				console.log('No Role Assigned');
				sessionStorage.removeItem('encodedAccessToken');
				this.router.navigate(['no-access']);
			} else {
				this.role = roles;
				sessionStorage.setItem("userRole", this.role);
				console.log('Role Assigned::' + this.role);
				if (this.role === 'eSRA_Buyer' || this.role === 'eSRA_JV-Buyer' || this.role === 'eSRA_SuperUser') {
					this.isBuyer = true;
				}
				if (this.role === 'eSRA_STA-Engineer' || this.role === 'eSRA_JV-STA-Engineer' || this.role === 'SIM_JV-STA-Engineer' || this.role === 'STASiteEngineer' ||
					this.role === 'eSRA_STA-Supervisor' || this.role === 'STASiteSupervisor' || this.role === 'SIM_JV-STA-Supervisor' || this.role === 'eSRA_SuperUser') {
					this.isSTA = true;
				}
				if (this.role === 'eSRA_Admin') {
					this.isAdmin = true;
				}
			}
			this.assignedToMe = sessionStorage.getItem('mySRA');
			if (this.role === 'SIM_JV-STA-Supervisor' || this.role === 'SIM_JV-STA-Manager' ||	//this.role === 'eSRA_JV-STA-Supervisor' ||
				this.role === 'eSRA_Admin' || this.role === 'eSRA_Read-Only') {
				if (this.isAdmin && (this.paramSite != null && this.paramPSA != null)) {
					this.showSRAForReopen();
				} else {
					if (sessionStorage.getItem("second") == null || sessionStorage.getItem("second") == ''
																		|| sessionStorage.getItem("second") == undefined) {
						this.showAllSRAs(false);
					}
				}
			} else if ((this.role === 'eSRA_Buyer' || this.role === 'eSRA_STA-Engineer') && (this.paramSite != null && this.paramPSA != null)) {
				this.reOpenMessage = true;
			}
			else {
				if (sessionStorage.getItem("second") == null || sessionStorage.getItem("second") == ''
																	|| sessionStorage.getItem("second") == undefined) {
					if (this.assignedToMe == null || this.assignedToMe == undefined || this.assignedToMe == 'first') {
						this.showMySRAs(false);
					} else {
						this.showAllSRAs(false);
					}
				}
			}
		});
	}
	showSRAForReopen(){
		this.siteRiskService.sraForReopen(this.paramPSA,this.paramSite).subscribe(reOpenSRA => {
			this.allSRAs = reOpenSRA;
			this.spinnerFlag = false;
		});
	}
	isFirstTime: string = 'first';
	showMySRAs(isClick) {
		this.clearDropdowns();
		this.isFirstTime = 'second';
		sessionStorage.setItem('second',this.isFirstTime);

		if(isClick) {
			this.resetSessionValues();
			this.assignedToMe = 'first';
			sessionStorage.setItem("mySRA", this.assignedToMe);
		}else{
			this.assignedToMe = sessionStorage.getItem('mySRA');
			if(this.assignedToMe == undefined || this.assignedToMe == null){
				this.assignedToMe = 'first';
				sessionStorage.setItem("mySRA", this.assignedToMe);
			}
		}
		this.reset();
		this.resetPTable();
		this.getSessionValues();
	}

	getSessionValues(){
		this.psaText = sessionStorage.getItem('psaTextData') != null ? sessionStorage.getItem('psaTextData') : "";
		this.siteText = sessionStorage.getItem('siteTextData') != null ? sessionStorage.getItem('siteTextData') : "";
		this.modelYearText = sessionStorage.getItem('modelYearTextData') != null ? sessionStorage.getItem('modelYearTextData') : "";
		this.commodityText = sessionStorage.getItem('commodityTextData') != null ? sessionStorage.getItem('commodityTextData') : "";
		this.planDateText = sessionStorage.getItem('planDateTextData') != null ? sessionStorage.getItem('planDateTextData') : "";
		this.forecastText = sessionStorage.getItem('forecastTextData') != null ? sessionStorage.getItem('forecastTextData') : "";
		this.buyerText = sessionStorage.getItem('buyerTextData') != null ? sessionStorage.getItem('buyerTextData') : "";
		//this.vhmText = sessionStorage.getItem('vhmTextData') != null ? sessionStorage.getItem('vhmTextData') : "";
		this.staText = sessionStorage.getItem('staTextData') != null ? sessionStorage.getItem('staTextData') : "";
		this.lastUpdatedText = sessionStorage.getItem('lastUpdatedTextData') != null ? sessionStorage.getItem('lastUpdatedTextData') : "";
		this.keyDropdown = sessionStorage.getItem('keyDropdown') != null ? sessionStorage.getItem('keyDropdown') : "";
		this.esfDropdown = sessionStorage.getItem('esfDropdown') != null ? sessionStorage.getItem('esfDropdown') : "";
		this.pmtDropdown = sessionStorage.getItem('pmtDropdown') != null ? sessionStorage.getItem('pmtDropdown') : "";
		this.statusDropdown = sessionStorage.getItem('statusDropdown') != null ? sessionStorage.getItem('statusDropdown') : "";
		this.vhmDropdown = sessionStorage.getItem('vhmDropdown') != null ? sessionStorage.getItem('vhmDropdown') : "";

	}
	async loadSRAsLazy(event: LazyLoadEvent){
		if(sessionStorage.getItem("second")) {
			let tempList = [];
			this.getSessionValues();
			if(this.keyDropdown !== '' && this.keyDropdown !== null && this.keyDropdown !== 'Select'){
				this.clearKeyDropdown = true;
			}
			if(this.keyDropdown === 'null' || this.keyDropdown === null || this.keyDropdown === '' || this.keyDropdown == 'Select'){
				this.clearKeyDropdown = false;
				this.keyDropdown='';
			}
			if(this.esfDropdown !== '' && this.esfDropdown !== null && this.esfDropdown !== 'Select'){
				this.clearESFDropdown = true;
			}if(this.esfDropdown === 'null' || this.esfDropdown === null || this.esfDropdown === '' || this.esfDropdown == 'Select'){
				this.clearESFDropdown = false;
				this.esfDropdown='';
			}

			if(this.pmtDropdown !== '' && this.pmtDropdown !== null && this.pmtDropdown !== 'Select'){
				this.clearPMTDropdown = true;
			}if(this.pmtDropdown === 'null' || this.pmtDropdown === null || this.pmtDropdown === '' || this.pmtDropdown == 'Select'){
				this.clearPMTDropdown = false;
				this.pmtDropdown='';
			}

			if(this.statusDropdown !== '' && this.statusDropdown !== null && this.statusDropdown !== 'Select'){
				this.clearStatusDropdown = true;
			}if(this.statusDropdown === 'null' || this.statusDropdown === null || this.statusDropdown === '' || this.statusDropdown == 'Select') {
				this.clearStatusDropdown = false;
				this.statusDropdown = '';
			}

			if(this.vhmDropdown !== '' && this.vhmDropdown !== null && this.vhmDropdown !== 'Select'){
				this.clearVHMDropdown = true;
			}if(this.vhmDropdown === 'null' || this.vhmDropdown === null || this.vhmDropdown === '' || this.vhmDropdown == 'Select') {
				this.clearVHMDropdown = false;
				this.vhmDropdown = '';
			}

			if (this.assignedToMe === 'first' || sessionStorage.getItem("mySRA") === 'first') {
				this.assignedToMe = 'first';
				sessionStorage.setItem("mySRA", this.assignedToMe);
				this.spinnerFlag = true;
				this.allSRAFromDB = null;
				this.allSRAs = null;
				await this.siteRiskService.getLoggedInUserSRAs(this.userID,
																sessionStorage.getItem("userRole")).subscribe(data => {
					if(data == null || data == ''){
						this.assignedNotToMe = 'You do not currently have any eSRAs assigned to you.' +
							' Please select “Show all eSRAs” to see existing records corresponding to your access.';
					} else {
						this.allSRAFromDB = data;
						this.sraFilterRequests(event, tempList, false);
						data.forEach((value) => {
							if ((this.role === 'eSRA_JV-Buyer' && this.userID === value.buyer.toUpperCase()) ||
								(this.role === 'eSRA_JV-STA-Engineer' && this.userID === value.staEng.toUpperCase())
								|| ((this.role === 'eSRA_JV-STA-Supervisor') && this.userID === value.staEngLL6.toUpperCase())) {
								sessionStorage.setItem("domainName", value.jvBuyerCompanyName);
							}
						})
					}
					this.spinnerFlag = false;
				}, error => {
					console.log(error.status);
					this.utilService.pushMsg('error', 'Error', 'Unable to retrieve data from eSRA database,' +
						' Please contact ESRASUPP@ford.com ' +
						'with a screenshot of this error message.');
					this.spinnerFlag = false;
				});
			}
			if(this.assignedToMe === 'second' || sessionStorage.getItem("mySRA") === 'second'){
				//alert("very first")
					this.currentPageSize = event.rows;
					this.pageNumber = (event.first / event.rows); //offset/itemsPerPage
					sessionStorage.setItem("number", this.pageNumber.toString());
					sessionStorage.setItem("size", this.currentPageSize.toString());
				this.assignedToMe = 'second';
				sessionStorage.setItem("mySRA", this.assignedToMe);
				this.spinnerFlag = true;
				this.siteRiskService.getAllSRAs(this.psaText, this.siteText, this.modelYearText, this.commodityText,
					this.keyDropdown, this.esfDropdown, this.planDateText, this.forecastText, this.pmtDropdown,
					this.buyerText, this.vhmDropdown, this.staText, this.statusDropdown, this.role, this.userID, this.companyDomain,
					sessionStorage.getItem("employeeType"),
					sessionStorage.getItem("companyName"), this.pageNumber, this.currentPageSize).subscribe(data => {
					console.log("--------Calling getAllSRAs");
					this.allSRAFromDB = data.sraData;
				    this.totalRecords = data.recordsSize;//for pagable only
					console.log("--------recordsSize"+data.recordsSize);
					this.sraFilterRequests(event,tempList,true);
					this.spinnerFlag = false;
				}, error => {
					console.log(error.status);
					this.utilService.pushMsg('error', 'Error', 'Unable to retrieve data from eSRA database, Please contact ESRASUPP@ford.com ' +
						'with a screenshot of this error message.');
					this.spinnerFlag = false;
				});
			}
			}
	}

	sraFilterRequests(event:LazyLoadEvent,tempList:any,isPagable:boolean){
		this.pmtOptions = [];
		Object.entries(this.allSRAFromDB).forEach(([key, value], index) => {
			if(value.pmt !=null && value.pmt != 'undefined' && value.pmt !='') {
				this.pmtOptions.push({label: value.pmt, value: value.pmt, title: 'allSRA'});
			}
			if (this.compareValue(value.psaNumber, this.psaText) &&
				this.compareValue(value.siteCode, this.siteText) &&
				this.compareValue(value.modelYear, this.modelYearText) &&
				this.compareValue(value.commodityCode, this.commodityText) &&
				this.compareDropdownValue(value.keyCommodity, this.keyDropdown) &&
				this.compareDropdownValue(value.esfCommodity, this.esfDropdown) &&
				this.compareValue(value.step2PlanDate, this.planDateText) &&
				this.compareValue(value.step2ForecastDate, this.forecastText) &&
				this.compareDropdownValue(value.pmt, this.pmtDropdown) &&
				this.compareValue(value.buyer, this.buyerText) &&
				this.compareDropdownValue(value.vhm, this.vhmDropdown) &&
				this.compareValue(value.staEng, this.staText) &&
				this.compareDropdownValue(value.recordComplete, this.statusDropdown) &&
				this.compareValue(value.recLastUpdatedUser, this.lastUpdatedText)) {
				tempList.push(value);
			}
		});
		if(!isPagable) {
			this.totalRecords = tempList.length; // non-pagable
		}
		this.allSRAs = tempList;
		this.sortData(event,tempList);
		if (tempList) {
			if(isPagable) {
				this.allSRAs = tempList; // for pagable only
			}else{
				this.allSRAs = tempList.slice(event.first, (event.first + event.rows)); // non-pagable
			}
		}
		this.pmtOptions = this.removeDuplicates(this.pmtOptions, "label");
		this.spinnerFlag = false;
	}

	sortData(event:LazyLoadEvent,tempList:any){
		if((event.sortField !== undefined)) {
			if(event.sortField === 'psaNumber') {
				if (event.sortOrder < 0) {
					tempList.sort((a, b) => a.psaNumber - b.psaNumber);
				} else {
					tempList.sort((a, b) => b.psaNumber - a.psaNumber);
				}
			}
			if(event.sortField === 'siteCode') {
				if (event.sortOrder < 0) {
					tempList.sort((a, b) => (a.siteCode < b.siteCode) ? -1 : 1);
				} else {
					tempList.sort((a, b) => (a.siteCode < b.siteCode) ? 1 : -1);
				}
			}
			if(event.sortField === 'commodityCode') {
				if (event.sortOrder < 0) {
					tempList.sort((a, b) => (a.commodityCode < b.commodityCode) ? -1 : 1);
				} else {
					tempList.sort((a, b) => (a.commodityCode < b.commodityCode) ? 1 : -1);
				}
			}
			if(event.sortField === 'keyCommodity') {
				if (event.sortOrder < 0) {
					tempList.sort((a, b) => (a.keyCommodity < b.keyCommodity) ? -1 : 1);
				} else {
					tempList.sort((a, b) => (a.keyCommodity < b.keyCommodity) ? 1 : -1);
				}
			}
			if(event.sortField === 'esfCommodity') {
				if (event.sortOrder < 0) {
					tempList.sort((a, b) => (a.esfCommodity < b.esfCommodity) ? -1 : 1);
				} else {
					tempList.sort((a, b) => (a.esfCommodity < b.esfCommodity) ? 1 : -1);
				}
			}
			if(event.sortField === 'step2PlanDate') {
				if (event.sortOrder < 0) {
					tempList.sort((a, b) => (a.step2PlanDate < b.step2PlanDate) ? -1 : 1);
				} else {
					tempList.sort((a, b) => (a.step2PlanDate < b.step2PlanDate) ? 1 : -1);
				}
			}
			if(event.sortField === 'step2ForecastDate') {
				if (event.sortOrder < 0) {
					tempList.sort((a, b) => (a.step2ForecastDate < b.step2ForecastDate) ? -1 : 1);
				} else {
					tempList.sort((a, b) => (a.step2ForecastDate < b.step2ForecastDate) ? 1 : -1);
				}
			}
			if(event.sortField === 'pmt') {
				if (event.sortOrder < 0) {
					tempList.sort((a, b) => (a.pmt < b.esfCommodity) ? -1 : 1);
				} else {
					tempList.sort((a, b) => (a.pmt < b.pmt) ? 1 : -1);
				}
			}
			if(event.sortField === 'buyer') {
				if (event.sortOrder < 0) {
					tempList.sort((a, b) => (a.buyer < b.buyer) ? -1 : 1);
				} else {
					tempList.sort((a, b) => (a.buyer < b.buyer) ? 1 : -1);
				}
			}
			if(event.sortField === 'staEng') {
				if (event.sortOrder < 0) {
					tempList.sort((a, b) => (a.staEng < b.staEng) ? -1 : 1);
				} else {
					tempList.sort((a, b) => (a.staEng < b.staEng) ? 1 : -1);
				}
			}
			if(event.sortField === 'recordComplete') {
				if (event.sortOrder < 0) {
					tempList.sort((a, b) => (a.recordComplete < b.recordComplete) ? -1 : 1);
				} else {
					tempList.sort((a, b) => (a.recordComplete < b.recordComplete) ? 1 : -1);
				}
			}
			if(event.sortField === 'recLastUpdatedUser') {
				if (event.sortOrder < 0) {
					tempList.sort((a, b) => (a.recLastUpdatedUser < b.recLastUpdatedUser) ? -1 : 1);
				} else {
					tempList.sort((a, b) => (a.recLastUpdatedUser < b.recLastUpdatedUser) ? 1 : -1);
				}
			}
			if(event.sortField === 'recLastUpdatedDate') {
				if (event.sortOrder < 0) {
					tempList.sort((a,b) => Date.parse(a.recLastUpdatedDate) - Date.parse(b.recLastUpdatedDate));
				} else {
					tempList.sort((a,b) => Date.parse(b.recLastUpdatedDate) - Date.parse(a.recLastUpdatedDate));
				}
			}
		}
	}
	 compareValue(dbValue:string,enterValue:string) : boolean{
		if(enterValue !=null && enterValue !='' && enterValue !=='null'){
			if(dbValue == null || dbValue == ''){
				return false;
			} else{
				return dbValue.toLowerCase().includes(enterValue.toLowerCase());
			}
		} else{
			return true;
		}
	}
	compareDropdownValue(dbValue:string,enterValue:string) : boolean{
		if(enterValue !=null && enterValue !='' && enterValue !=='null'){
			if(enterValue === dbValue) {
				return dbValue.includes(enterValue);
			} else{
				return false;
			}
		} else{
			return true;
		}
	}
	resetSessionValues(){
		sessionStorage.setItem('psaTextData','');
		sessionStorage.setItem('siteTextData','');
		sessionStorage.setItem('modelYearTextData','');
		sessionStorage.setItem('commodityTextData','');
		sessionStorage.setItem('planDateTextData','');
		sessionStorage.setItem('forecastTextData','');
		sessionStorage.setItem('buyerTextData','');
		sessionStorage.setItem('vhmDropdown','');
		sessionStorage.setItem('staTextData','');
		sessionStorage.setItem('lastUpdatedTextData','');
		sessionStorage.setItem('keyDropdown','');
		sessionStorage.setItem('esfDropdown','');
		sessionStorage.setItem('pmtDropdown','');
		sessionStorage.setItem('statusDropdown','');
	}

	storeTextValue(event: any,value:string){
		event.lazyLoadOnInit = true;
		this.loadScreen = true;
		if(value === 'psa'){
			sessionStorage.setItem('psaTextData',event.target.value.trim())
		}
		if(value === 'site'){
			sessionStorage.setItem('siteTextData',event.target.value.trim())
		}
		if(value === 'model'){
			sessionStorage.setItem('modelYearTextData',event.target.value.trim())
		}
		if(value === 'commodity'){
			sessionStorage.setItem('commodityTextData',event.target.value.trim())
		}
		if(value === 'planDate'){
			sessionStorage.setItem('planDateTextData',event.target.value.trim())
		}
		if(value === 'forecastDate'){
			sessionStorage.setItem('forecastTextData',event.target.value.trim())
		}
		if(value === 'vhm'){
			sessionStorage.setItem('vhmTextData',event.target.value.trim())
		}
		if(value === 'buyer'){
			sessionStorage.setItem('buyerTextData',event.target.value.trim())
		}
		if(value === 'sta'){
			sessionStorage.setItem('staTextData',event.target.value.trim())
		}
		if(value === 'lastUpdate'){
			sessionStorage.setItem('lastUpdatedTextData',event.target.value.trim())
		}
	}
	storeDropdownValue(value: string, tempValue: string, datafilter: string) {
		if (tempValue === 'keyCommodity') {
			sessionStorage.setItem('keyDropdown', value)
		}
		if (tempValue === 'esfCommodity') {
			sessionStorage.setItem('esfDropdown', value)
		}
		if (tempValue === 'pmt') {
			sessionStorage.setItem('pmtDropdown', value)
		}
		if (tempValue === 'status') {
			sessionStorage.setItem('statusDropdown', value)
		}
		if (tempValue === 'vhm') {
			sessionStorage.setItem('vhmDropdown', value)
		}
	}
	clearFilters() {
		if(this.assignedToMe !=null && this.assignedToMe !='' && this.assignedToMe != undefined){
			this.clearDropdowns();
			if(this.assignedToMe == 'first'){
				this.showMySRAs(true)
			}
			if(this.assignedToMe == 'second'){
				this.showAllSRAs(true)
			}
		}else{
			this.resetSessionValues();
		}
	}
	clearDropdowns() {
		this.clearKeyDropdown = false;
		this.clearESFDropdown = false;
		this.clearPMTDropdown = false;
		this.clearStatusDropdown = false;
		this.clearVHMDropdown = false;
	}
	showAllSRAs(isClick) {
		this.clearDropdowns();
		this.isFirstTime = 'second';
		sessionStorage.setItem('second',this.isFirstTime);

		if(isClick) {
			this.resetSessionValues();
			this.assignedToMe = 'second';
			sessionStorage.setItem("mySRA", this.assignedToMe);
		}else{
			this.assignedToMe = sessionStorage.getItem('mySRA');
			this.assignedToMe = 'second';
			sessionStorage.setItem("mySRA", this.assignedToMe);
		}
		this.reset();
		this.spinnerFlag = true;
		this.success = '';
		this.assignedNotToMe = '';
		this.resetPTable();
		this.getSessionValues();
	}

	removeDuplicates(myArray, Prop) {
		return myArray.filter((obj, pos, arr) => {
			return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
		});
	}
	createSRADetails() {
		this.reset();
		this.success = '';
		if ((this.siteCode === undefined && this.psaNumber === undefined) ||  (this.siteCode === '' && this.psaNumber === '')) {
			this.errorMessage = 'Both PSA Number & Site Code are required.';
		} else if ((this.psaNumber === undefined || this.psaNumber === '') &&  (this.siteCode !== null || this.siteCode !== '' )) {
			this.errorMessage = 'PSA Number is required.';
		} else if ((this.siteCode === undefined || this.siteCode === '') &&  (this.psaNumber !== undefined || this.psaNumber.length > 0 )) {
			this.errorMessage = 'Site Code is required.';
		} else if (this.siteCode !== undefined && this.siteCode.length <= 4 ) {
			this.errorMessage = 'Site Code length should be 5 characters.';
		} else if (this.siteCode !== undefined && this.siteCode.length >= 5 &&  !this.siteCode.match('^[A-Z0-9]+$')) {
			this.errorMessage = 'Site Code allows only alphanumeric values.';
		} else if (this.psaNumber !== undefined && this.psaNumber.length > 0 && !this.psaNumber.match('^[0-9]*$')) {
			this.errorMessage = 'PSA Number allows only numeric values.';
		} else {
			if ((this.siteCode.length >= 5 && this.siteCode.match('^[A-Z0-9]+$') && (this.psaNumber.length > 0 &&
				this.psaNumber.match('^[0-9]*$')))) {
				this.siteRiskService.isRecordAvailable(this.siteCode, this.psaNumber).subscribe(data => {
					if (data !== null) {
						this.siteDetails = data;
						if ((this.siteCode ===  this.siteDetails.siteCode) && (this.psaNumber === this.siteDetails.psaNumber)) {
							this.errorMessage = 'eSRA for this Supplier/PSA combination already exists.';
						}
					} else {
						this.siteService.getPSADetails(this.psaNumber).subscribe(psa => {
								this.psaData = psa;
								if (this.psaData == null) {
									console.log('No PSA data Available');
									this.errorMessage = 'Enter Valid PSA Number.';
								} else {
									this.siteService.getSIMSiteDetails(this.siteCode).subscribe(site => {
										this.siteData = site;
										if (site == null || site === '') {
											console.log('Site not in SIM');
											this.confirmationService.confirm({
												key: 'confirm-noSiteInSIM-eSRA',
												message: 'This Site does not have a Production STA assigned in SIM.'
													+ '<br/><br>' + 'Are you sure you want to proceed?',
												reject: () => {
													this._NoGSDBCheck();
												},
												accept: () => {
													this.globalSiteCheck();
												},
											});
										} else {
											this.router.navigate(['createesra', this.siteCode, this.psaNumber]);
										}
									}, error => {
										console.log(error.status);
										this.utilService.pushMsg('error', 'Error', 'Unable to retrieve data from SIM, Please contact ESRASUPP@ford.com' +
											' with a screenshot of this error message.');
									});
								}
							}
							, error => {
								console.log(error.status);
								this.utilService.pushMsg('error', 'Error', 'Unable to retrieve data from SWT, Please contact ESRASUPP@ford.com ' +
									'with a screenshot of this error message.');
							})
					}
				});
			}
		}
	}
	_NoGSDBCheck() {
		console.log('eSRA Create dropped');
	}

	globalSiteCheck() {
		//alert(this.siteCode+"-------------site code");
		//alert(this.reassignSiteCode+"---------reassignSiteCode");
		this.siteService.getGSDBSiteDetails(this.siteCode).subscribe(globalSite => {
			this.siteData = globalSite;
			if (this.siteData == null) {
				this.errorMessage = 'Enter Valid Manufacturing Site Code.';
			}
			if (this.psaData != null && this.siteData != null) {
				this.router.navigate(['createesra', this.siteCode, this.psaNumber]);
			}
		});
	}

	reassignGlobalSiteCheck() {
		this.siteService.getGSDBSiteDetails(this.reassignSiteCode).subscribe(globalSiteData => {
				this.confirmation='';
				this.isSimSite = 'no';
				//this.newSiteLeadSTA=true;
				if (globalSiteData == null) {
					this.utilService.pushMsg('error', 'Error', 'Enter Valid Manufacturing Site Code.');
				} else{
					for (const key in globalSiteData) {
						if (Object.prototype.hasOwnProperty.call(globalSiteData, key)) {
							const value = globalSiteData[key];
							console.log(key + ': ' + value);
						}
					}
					this.siteRiskDetails.reAssignSiteData = globalSiteData;
				}
			},
			error => {
				console.log(error.status);
				this.utilService.pushMsg('error', 'Error', 'Unable to retrieve data from SIM, Please contact ESRASUPP@ford.com' +
					' with a screenshot of this error message.');
			});
	}
	updateSRADetails(psa: string, site: string) {
		this.router.navigate(['updateesra', site, psa]);
	}

	viewSRADetails(psa: string, site: string) {
		this.router.navigate(['viewesra', psa, site, 'PsaAndSite']);
	}

	copyForCreateSRA(psa: string, site: string) {
		this.router.navigate(['copyesra', psa, site]);
	}

	comparativeSummaryByPSA(psa: string) {
		this.router.navigate(['viewesra', psa, 'PSA']);
	}

	unlockSRA(allSRAs: UpdateeSRA) {
		this.confirmationService.confirm({
			key: 'confirm-unlock-eSRA' ,
			message: 'Are you sure you would like to unlock?',
			reject: () => { this._unlockNo(); },
			accept: () => { this.unlockYes(allSRAs); },
		});
	}

	private _unlockNo() {
		console.log('eSRA unlock dropped');
	}

	private unlockYes(allSRAs) {
		this.siteRiskService.unlockSRA(allSRAs).subscribe(data => {
			this.siteDetails = data;
			if (data !== null && data.recordComplete === 'N') {
				this.utilService.pushMsg('success', 'Success', 'eSRA request has been successfully unlocked');
				this.showAllSRAs(false);
			}
		}, error => {
			console.log(error.status);
			this.utilService.pushMsg('error', 'Error', 'Unable to unlock eSRA , Please try again later');
		});
	}
	dateTest(value: string) {
		return value + ' GMT+0';
	}
	private reset() {
		this.errorMessage = '';
	}
	onDelete(deleteSRA: UpdateeSRA[]) {
		this.tempSRA = deleteSRA;
		this.success = '';
		this.displayModal = true;
		this.reasonForDelete = false;
		this.deleteReason = '';
	}
	deleteSRA(deleteReason: string) {
		this.siteRiskService.delete(this.tempSRA, deleteReason).subscribe(value => {
			console.log(value);
			this.utilService.pushMsg('success', 'Success', 'eSRA has been deleted successfully.');
			this.deleteReason = null;
			this.resetTemp();
			this.showMySRAs(false);
		}, error => {
			console.log(error.status);
			this.utilService.pushMsg('warn', 'Warning', 'Unable to delete eSRA!.');
		});
	}
	deleteYes() {
		this.reasonForDelete = true;
	}
	resetTemp() {
		this.tempPsa = null;
		this.tempSite = null;
		this.tempAddUsers = null;
		this.tempSTA = null;
		this.tempBuyer = null;
	}
	handleKeyUp(e,value:string){
		this.success = '';
		this.errorMessage = '';
		if(e.keyCode === 13){
			this.createSRADetails();
		}
		console.log("handleKeyUp:"+value);
	}
	autoFocusCheck() {
		this.f = true
	}
	resetPTable(){
		this.dt.reset();
	}
	protected readonly event = event;

	displayModalReassign: boolean;
	reassignSiteCode: string;
	isSimSite: string;
	siteReassign: boolean;
	simOrGlobal:string;
	greenORBrown:string;
	SELECT_COLOR: string = '---Select Color---';
	isCurrentSiteInSIM:string;

	displayDialog(psa: string, site: string, simOrglobal: string, greenOrBrown) {
		this.siteCode = site; // current site
		this.psaNumber = psa; // current psa
		this.simOrGlobal = simOrglobal;
		this.greenORBrown = greenOrBrown
		this.displayModalReassign = true;
		this.isSimSite = 'yes';
		this.reassignSiteCode = null;
		this.siteReassign = false;
	}
	reassignKeepClear(value:string) {
		this.keepClear = value;
		this.siteReassign = true;
		this.isSimSite = 'no';
		this.getDataForUpdate() ;
	}
	getDataForUpdate() {
		this.siteRiskService.updateSiteDetails(this.siteCode, this.psaNumber, this.role).subscribe(data => {
			this.siteRiskDetails = data;
			this.siteRiskDetails.qualtiyRiskColor = this.getColorNames(this.siteRiskDetails.qualtiyRiskColor);
			if (this.siteRiskDetails.greenORbrown != null) {
				if (((this.siteRiskDetails.greenORbrown == 'BROWNFIELD' || this.siteRiskDetails.greenORbrown == 'GREENFIELD' ||
						this.siteRiskDetails.greenORbrown == 'NO') && this.siteRiskDetails.simOrGSDBSite == 'N')
					|| ((this.siteRiskDetails.greenORbrown == 'BROWNFIELD' || this.siteRiskDetails.greenORbrown == 'GREENFIELD') &&
						this.siteRiskDetails.simOrGSDBSite == 'Y')) {//Global
					this.setQualityColors();
				}
			}
			this.setCapSupProColors()
		})
	}

	getColorNames(colorCode:string) {
		if(colorCode == null) {
			colorCode = this.SELECT_COLOR;
		} else {
			if (colorCode == 'B' || colorCode == 'BLUE')
				colorCode = "BLUE"
			else if (colorCode == 'R' || colorCode == 'BLUE')
				colorCode = "RED"
			else if (colorCode == 'Y' || colorCode == 'YELLOW')
				colorCode = "YELLOW"
			else if (colorCode == 'G' || colorCode == 'GREEN')
				colorCode = "GREEN"
		}
		return colorCode;
	}

	setQualityColors() {
			this.siteRiskDetails.q1CodeColor = this.getColorNames(this.siteRiskDetails.q1CodeColor);
			this.siteRiskDetails.ppmColor = this.getColorNames(this.siteRiskDetails.ppmColor);
			this.siteRiskDetails.openwrntySpikesColor = this.getColorNames(this.siteRiskDetails.openwrntySpikesColor);
			this.siteRiskDetails.stopshipmentsColor = this.getColorNames(this.siteRiskDetails.stopshipmentsColor);
			this.siteRiskDetails.openFSAColor = this.getColorNames(this.siteRiskDetails.openFSAColor);
			this.siteRiskDetails.dlySixMonthsAvgColor = this.getColorNames(this.siteRiskDetails.dlySixMonthsAvgColor);
			this.siteRiskDetails.priorLaunchPrfColor = this.getColorNames(this.siteRiskDetails.priorLaunchPrfColor);
			this.siteRiskDetails.qltySysQ1MSAColor = this.getColorNames(this.siteRiskDetails.qltySysQ1MSAColor);
	}

	setCapSupProColors(){
		this.siteRiskDetails.healthChartColor = this.getColorNames(this.siteRiskDetails.healthChartColor);
		this.siteRiskDetails.ll4Site = this.getColorNames(this.siteRiskDetails.ll4Site);
		this.siteRiskDetails.sharedCpty = this.getColorNames(this.siteRiskDetails.sharedCpty);
		this.siteRiskDetails.localLabor = this.getColorNames(this.siteRiskDetails.localLabor);
		this.siteRiskDetails.opePattern = this.getColorNames(this.siteRiskDetails.opePattern);
		this.siteRiskDetails.manfReadiness = this.getColorNames(this.siteRiskDetails.manfReadiness);
		this.siteRiskDetails.techCapability= this.getColorNames(this.siteRiskDetails.techCapability);
		this.siteRiskDetails.siteLdrCpty = this.getColorNames(this.siteRiskDetails.siteLdrCpty);
		this.siteRiskDetails.finRisk = this.getColorNames(this.siteRiskDetails.finRisk);
		this.siteRiskDetails.ownership = this.getColorNames(this.siteRiskDetails.ownership);
		this.siteRiskDetails.localToolCpty = this.getColorNames(this.siteRiskDetails.localToolCpty);
		this.siteRiskDetails.mulLaunch= this.getColorNames(this.siteRiskDetails.mulLaunch);
		this.siteRiskDetails.ilvsExp = this.getColorNames(this.siteRiskDetails.ilvsExp);
		this.siteRiskDetails.subtierMgmt = this.getColorNames(this.siteRiskDetails.subtierMgmt);
		this.siteRiskDetails.dt2Mpa= this.getColorNames(this.siteRiskDetails.dt2Mpa);
		this.siteRiskDetails.newDsnTech= this.getColorNames(this.siteRiskDetails.newDsnTech);
		this.siteRiskDetails.gtcCompl = this.getColorNames(this.siteRiskDetails.gtcCompl);
		this.siteRiskDetails.plannesPSW = this.getColorNames(this.siteRiskDetails.plannesPSW);
	}

	reassignToNewSiteCode(value:string) {
		if (this.reassignSiteCode.length <= 4) {
			this.utilService.pushMsg('error', 'Error', 'Site Code length should be 5 characters.');
		} else if (this.reassignSiteCode.length >= 5 && !this.reassignSiteCode.match('^[A-Z0-9]+$')) {
			this.utilService.pushMsg('error', 'Error', 'Site Code allows only alphanumeric values.');
		} else {
			this.siteRiskService.isRecordAvailable(this.reassignSiteCode, this.psaNumber).subscribe(data => {
				if (data !== null) {
					if (this.reassignSiteCode === data.siteCode && this.psaNumber === data.psaNumber) {
						this.utilService.pushMsg('warn', 'Warning', 'eSRA for this Supplier/PSA combination already exists.');
					}
				} else {
					if(this.simAndGsdb == "gsdb"){
						this.reassignGlobalSiteCheck();
						this.updateSimOrGsdb("N");
					} else {
						this.siteService.getSIMSiteDetails(this.reassignSiteCode).subscribe(data => {
							if (data == null || data === '') {
									this.isSimSite = "gsdb";
									this.simAndGsdb = "gsdb";
							} else {
								this.siteRiskDetails.reAssignSiteData = data;
								this.updateSimOrGsdb("Y");
								this.simAndGsdb = "sim";
							}
							}, error => {
								console.log(error.status);
								this.utilService.pushMsg('error', 'Error', 'Unable to retrieve data from SIM, Please contact ESRASUPP@ford.com' +
										' with a screenshot of this error message.');
						});
					}
				}
			})
		}
	}
	keepClear:string;
	updateSimOrGsdb(isReassignSiteInSIM:string){
			this.siteRiskService.updateReassignSite(this.siteRiskDetails, this.reassignSiteCode, this.keepClear,this.siteRiskDetails.simOrGSDBSite,isReassignSiteInSIM).subscribe(data => {
				this.utilService.pushMsg('success', 'Success', 'Site Code has been re-assigned successfully.');
				let url = window.location.href;
				if(url.indexOf('reassign')>=0) {
					this.router.navigate(['home']);
				}else{
					this.router.navigate(['home/reassign']);
				}
			});
	}
}

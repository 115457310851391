import { Component, OnInit } from '@angular/core';
import { ReportService } from '../services/reports/report.service';
import {SiteCodesDropdown} from "../models/reports/sitecodes";
import {SiteCodesDropdownResponse} from "../models/reports/view-dropDown-response";
import {ProgramsDropdown} from "../models/reports/programs";
import {ProgramsDropdownResponse} from "../models/reports/view-ProgramDropDown-response";
import {HttpClient} from '@angular/common/http';
import {UserIdService} from '../services/user-id.service.service';
import {SelectItem} from 'primeng/api';
import {CommoditiesDropdownResponse} from '../models/reports/view-CommodityDropDown-response';
import {CommoditiesDropdown} from '../models/reports/commodities';
import {PMTDropdown} from '../models/reports/pmt';
import {PMTDropdownResponse} from '../models/reports/view-PMTDropDown-response';
import {ApsService} from '../aps.service';
import {Router} from '@angular/router';
import {formatDate } from '@angular/common';
import {UtilService} from '../services/util.service';
import {STAOrgCodesDropdownResponse} from '../models/reports/view-STAOrgCodeDropdown-response';
import {STAOrgCodeDropdown} from '../models/reports/staOrgCodes';
import {SuppliersDropdown} from '../models/reports/suppliers';
import {SuppliersDropdownResponse} from '../models/reports/view-Suppliers-dropDown-response';
import {VHMDropdown} from '../models/reports/vhm';
import {VHMDropdownResponse} from '../models/reports/view-VHMDropDown-response';

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

	today= new Date();
	dateTime = '';

	downLoadButton:boolean=true;
	userID: string;
	role: string;

	spinnerFlag = false;

	completed:boolean = true;
	inProgress:boolean = false;

	qualityMitPlan:boolean = false;
	capacityMitPlan:boolean = false;
	supplierMitPlan:boolean = false;
	programMitPlan:boolean = false;

	psaMasterSelected:boolean;
	psaCheckList:any;
	psaCheckedList:any;

	qualityMasterSelected:boolean;
	qualityCheckList:any;
	qualityCheckedList:any;

	capacityMasterSelected:boolean;
	capacityCheckList:any;
	capacityCheckedList:any;

	supplierMasterSelected:boolean;
	supplierCheckList:any;
	supplierCheckedList:any;

	programMasterSelected:boolean;
	programCheckList:any;
	programCheckedList:any;

	staMasterSelected:boolean;
	staCheckList:any;
	staCheckedList:any;

	greenBrownFields: SelectItem[];
	keyCommodity: SelectItem[];
	esfCommodity: SelectItem[];
	staOrgCodes: SelectItem[];

	greenBrownFields1: SelectItem[];
	keyCommodity1: SelectItem[];
	esfCommodity1: SelectItem[];
	staOrgCodes1: SelectItem[] ;

	selectedSiteCodes: SiteCodesDropdown[];
	siteCodesDropdownResponse: SiteCodesDropdownResponse;

	selectedSuppliers: SuppliersDropdown[];
	suppliersDropdownResponse: SuppliersDropdownResponse;

	selectedPrograms:ProgramsDropdown[];
	programsDropdownResponse: ProgramsDropdownResponse;

	selectedCommodities:CommoditiesDropdown[];
	commoditiesDropdownResponse:CommoditiesDropdownResponse;

	selectedSTAOrgCodes:STAOrgCodeDropdown[];
	orgCodesDropdownResponse:STAOrgCodesDropdownResponse;

	selectedPMT:PMTDropdown[];
	pmtDropdownResponse:PMTDropdownResponse;

	selectedVHM:VHMDropdown[];
	vhmDropdownResponse:VHMDropdownResponse;

	excelReportData:any;
	private blob: Blob;

	employeeType: string;
	companyDomain: string;
	companyName: string;

	constructor(private excelDownloadReport: ReportService,private http: HttpClient,private reportService: ReportService,
				private userIdService: UserIdService,private apsService: ApsService,private router: Router,private utilService: UtilService) {

		this.dateTime = formatDate(this.today, 'dd-MM-yyyy', 'en-US', '+0530');

		this.psaMasterSelected = true;
		this.qualityMasterSelected = true;
		this.capacityMasterSelected = true;
		this.supplierMasterSelected = true;
		this.programMasterSelected = true;
		this.staMasterSelected = true;

	  this.greenBrownFields = [
		  {label: "N/A", value: "NO" },
		  {label: "Greenfield", value: "GREENFIELD" },
		  {label: "Brownfield", value: "BROWNFIELD"},
		  {label: "Undefined", value: "Undefined" }
	  ];

	  this.keyCommodity = [
		  {label: "Key Commodity", value: "Key Commodity" },
		  {label: "Non-Key Commodity", value: "Non-Key Commodity"},
		  {label: "Undefined", value: "Undefined" }
	  ];

	  this.esfCommodity = [
		  {label: "ESF", value: "ESF"},
		  {label: "Non-ESF", value: "Non-ESF" },
		  {label: "Undefined", value: "Undefined" }
	  ];

	  this.staOrgCodes = [];

	  this.psaMasterSelected = true;
	  this.psaCheckList = [
		  {value:'Supplier Name',isSelected:true},
		  {value:'Program',isSelected:true},
		  {value:'Commodity',isSelected:true},
		  {value:'PMT',isSelected:true},
		  {value:'VHM',isSelected:true},
		 /* {value:'Key Commodity',isSelected:true},
		  {value:'ESF Commodity',isSelected:true},*/
		  {value:'GCST Step 2 Plan Date',isSelected:true},
		  {value:'GCST Step 2 Forecast Date',isSelected:true},
		  /*{value:'PMT',isSelected:true},*/
		  {value:'Greenfield/Brownfield',isSelected:true},
		  {value:'Buyer',isSelected:true},
		  {value:'Lead STA(Site/Core)',isSelected:true}
	  ];

	  this.qualityMasterSelected = true;
	  this.qualityCheckList = [
		  {value:'Overall Risk',isSelected:true},
		  {value:'Q1 Score',isSelected:true},
		  {value:'Q1 Status',isSelected:true},
		  {value:'PPM',isSelected:true},
		  {value:'Warranty Performance',isSelected:true},
		  {value:'Stop Ships in Last 6 Months',isSelected:true},
		  {value:'FSA in Last 6 Months',isSelected:true},
		  {value:'Delivery 6 Month Weighted Average',isSelected:true},
		  {value:'Prior Launch Performance',isSelected:true},
		  {value:'Quality System - Q1 MSA',isSelected:true}
	  ];

		this.supplierMasterSelected = true;
		this.supplierCheckList = [
			{value:'Overall Risk',isSelected:true},
			{value:'Technical Capability and Resources',isSelected:true},
			{value:'Site Leadership Stability',isSelected:true},
			{value:'Financial Risk',isSelected:true},
			{value:'Ownership',isSelected:true},
			{value:'Tool Capability / Management',isSelected:true},
			{value:'Multiple Launches / Programs',isSelected:true},
			{value:'ILVS Experience',isSelected:true},
			{value:'Sub-Tier Management',isSelected:true},
			{value:'Level 4 Site (Travel Restricted)',isSelected:true},
			{value:'Health Chart Compliant',isSelected:true}
		];

	  this.capacityMasterSelected = true;
	  this.capacityCheckList = [
		  {value:'Overall Risk',isSelected:true},
		  {value:'Shared Capacity',isSelected:true},
		  {value:'Local Labor / Workforce Expertise',isSelected:true},
		  {value:'Operating Pattern',isSelected:true},
		  {value:'Manufacturing / Capital Equipment Readiness',isSelected:true},
	  ];

	  this.programMasterSelected = true;
	  this.programCheckList = [
		  {value:'Overall Risk',isSelected:true},
		  {value:'DT2 / MPA / Bailment',isSelected:true},
		  {value:'New Design, Manufacturing Technology',isSelected:true},
		  {value:'GT&C Compliance',isSelected:true},
		  {value:'Planned PSW Timing',isSelected:true},
	  ];

	  this.staMasterSelected = true;
	  this.staCheckList = [
		  {value:'STA Aligned?',isSelected:true},
		  {value:'STA LL6 Verified',isSelected:true},
		  {value:'STA Verified Date',isSelected:true},
		  {value:'STA Comments',isSelected:true},
		  {value:'Buyer Comments',isSelected:true},
	  ];
	  this.getCheckedItemList();
  }

	ngOnInit() {
		this.spinnerFlag = true;
		this.reset();
		this.userIdService.userID.subscribe((value: string) => {
			this.userID = value.toUpperCase();
			this.getRoles();

			/*domain name from mail id*/
			this.userIdService.mail.subscribe((value: string) => {
				this.companyDomain = value;
				if(this.companyDomain.includes("@")){
					this.companyDomain = this.companyDomain.split('@')[1];
				}else{
					this.companyDomain = this.companyDomain;
				}
			});

			/*get logged in user id*/
			this.userIdService.userID.subscribe((value: string) => {
				this.userID = value.toUpperCase();
				sessionStorage.setItem("userID", this.userID);
				console.log("UserID form Session Storage::"+sessionStorage.getItem("userID"));
			});

			/*get employee type*/
			this.userIdService.employeeType.subscribe((value: string) => {
				this.employeeType = value.toUpperCase();
				sessionStorage.setItem("employeeType", this.employeeType);
				console.log("Employee Type from Session Storage::"+sessionStorage.getItem("employeeType"));
			});

			/*get Company Name*/
			this.userIdService.companyName.subscribe((value: string) => {
				this.companyName = value;
				sessionStorage.setItem("companyName", this.companyName);
				console.log("Employee Type from Session Storage::"+sessionStorage.getItem("companyName"));
			});
		});
	}

	private getRoles() {
		this.apsService.getRoles().subscribe(assignedRole => {
			this.role = assignedRole;
			console.log("Role for Reports::"+this.role);
			if(this.role !== null && this.role !== ''){
					this.showMyExcelDropdownsData('completed')
			} else {
				this.router.navigate(['no-access']);
			}
		});
	}

	// The master checkbox will check/ uncheck all items
	checkUncheckAll(name:string) {

		if(name == 'psa') {
			for (let i = 0; i < this.psaCheckList.length; i++) {
				this.psaCheckList[i].isSelected = this.psaMasterSelected;
			}
		}
		if(name == 'quality') {
			for (let i = 0; i < this.qualityCheckList.length; i++) {
				this.qualityCheckList[i].isSelected = this.qualityMasterSelected;
			}
		}
		if(name == 'capacity') {
			for (let i = 0; i < this.capacityCheckList.length; i++) {
				this.capacityCheckList[i].isSelected = this.capacityMasterSelected;
			}
		}
		if(name == 'supplier') {
			for (let i = 0; i < this.supplierCheckList.length; i++) {
				this.supplierCheckList[i].isSelected = this.supplierMasterSelected;
			}
		}
		if(name == 'program') {
			for (let i = 0; i < this.programCheckList.length; i++) {
				this.programCheckList[i].isSelected = this.programMasterSelected;
			}
		}
		if(name == 'sta') {
			for (let i = 0; i < this.staCheckList.length; i++) {
				this.staCheckList[i].isSelected = this.staMasterSelected;
			}
		}
		this.getCheckedItemList();
	}

	private retrieveSPC(userCheck:string) {
		this.reset();
		this.reportService.getSiteCodes(userCheck, this.role,this.companyDomain,this.employeeType,this.companyName).subscribe(siteCodes => {
			this.siteCodesDropdownResponse = siteCodes;
		});
		this.reportService.getSuppliers(userCheck, this.role,this.companyDomain,this.employeeType,this.companyName).subscribe(suppliers => {
			this.suppliersDropdownResponse = suppliers;
		});
		this.reportService.getPrograms(userCheck, this.role,this.companyDomain,this.employeeType,this.companyName).subscribe(programs => {
			this.programsDropdownResponse = programs;
		});
		this.reportService.getCommodities(userCheck, this.role,this.companyDomain,this.employeeType,this.companyName).subscribe(commodities => {
			this.commoditiesDropdownResponse = commodities;this.spinnerFlag = false;
		});
		this.reportService.getPMT(userCheck, this.role,this.companyDomain,this.employeeType,this.companyName).subscribe(pmt => {
			this.pmtDropdownResponse = pmt;this.spinnerFlag = false;
		});
		this.reportService.getVHM(userCheck, this.role,this.companyDomain,this.employeeType,this.companyName).subscribe(vhm => {
			this.vhmDropdownResponse = vhm;this.spinnerFlag = false;
		});
		this.reportService.getSTAOrgCodes().subscribe(staOrgCodes => {
			this.orgCodesDropdownResponse = staOrgCodes;this.spinnerFlag = false;
		});

		this.reportService.getSTAOrgCodesAndSiteCodes().subscribe(staOrgCodesAndSiteCodes => {
			this.orgCodesDropdownResponse = staOrgCodesAndSiteCodes;this.spinnerFlag = false;
		});
	}

	// Check All Checkbox Checked
	isAllSelected(section:string) {
		if (section === 'completed' || section === 'progress') {
			if(this.completed == false && this.inProgress != false){
				console.log("only inProgress records")
				this.retrieveSPC('N');
			}
			if(this.inProgress == false && this.completed != false){
				console.log("only completed records")
				this.retrieveSPC('Y');
			}
			if(this.completed == true && this.inProgress == true){
				console.log("all records")
				this.retrieveSPC('YAndN');
			}
			if(this.completed == false && this.inProgress == false){
				this.reset1()
				console.log("no records")
			}
		}

		if (section === 'psaData') {
			this.psaMasterSelected = this.psaCheckList.every(function (item: any) {
				return item.isSelected == true;
			})
		}

		if (section === 'qualityRisk') {
			this.qualityMasterSelected = this.qualityCheckList.every(function(item:any) {
				return item.isSelected == true;
			})
		}

		if (section === 'capacityRisk') {
			this.capacityMasterSelected = this.capacityCheckList.every(function (item: any) {
				return item.isSelected == true;
			})
		}

		if (section === 'supplierRisk') {
			this.supplierMasterSelected = this.supplierCheckList.every(function (item: any) {
					return item.isSelected == true;
			})
		}

		if (section === 'programRisk') {
			this.programMasterSelected = this.programCheckList.every(function (item: any) {
				return item.isSelected == true;
			})
		}

		if (section === 'staComments') {
			this.staMasterSelected = this.staCheckList.every(function (item: any) {
				return item.isSelected == true;
			})
		}
		this.getCheckedItemList();
	}

	// Get List of Checked Items
	getCheckedItemList(){

		this.psaCheckedList = [];
		for (let i = 0; i < this.psaCheckList.length; i++) {
			if(this.psaCheckList[i].isSelected)
				this.psaCheckedList.push(this.psaCheckList[i]);
		}

		this.qualityCheckedList = [];
		for (let i = 0; i < this.qualityCheckList.length; i++) {
			if(this.qualityCheckList[i].isSelected)
				this.qualityCheckedList.push(this.qualityCheckList[i]);
		}

		this.capacityCheckedList = [];
		for (let i = 0; i < this.capacityCheckList.length; i++) {
			if(this.capacityCheckList[i].isSelected)
				this.capacityCheckedList.push(this.capacityCheckList[i]);
		}

		this.supplierCheckedList = [];
		for (let i = 0; i < this.supplierCheckList.length; i++) {
			if(this.supplierCheckList[i].isSelected)
				this.supplierCheckedList.push(this.supplierCheckList[i]);
		}

		this.programCheckedList = [];
		for (let i = 0; i < this.programCheckList.length; i++) {
			if(this.programCheckList[i].isSelected)
				this.programCheckedList.push(this.programCheckList[i]);
		}
		this.staCheckedList = [];
		for (let i = 0; i < this.staCheckList.length; i++) {
			if(this.staCheckList[i].isSelected)
				this.staCheckedList.push(this.staCheckList[i]);
		}
	}

	public showMyExcelDropdownsData(sra:string) {
		this.reset();
		let userCheck;
		if(sra == 'mySRA'){
			userCheck = this.userID;
		}
		if(sra == 'allSRA'){
			userCheck = 'noUser';
		}
		if(sra == 'completed'){
			userCheck = 'Y';
		}
		this.retrieveSPC(userCheck);
		this.downLoadButton = false;
	}

	reset() {
		this.reset1();
		this.selectedSiteCodes = null;
		this.selectedSuppliers = null;
		this.selectedPrograms = null;
		this.selectedCommodities = null;
		this.selectedPMT = null;
		this.selectedVHM = null
		this.greenBrownFields1 = null;
		this.keyCommodity1 = null;
		this.esfCommodity1 = null;
		this.staOrgCodes1 = null;
	}

	reset1() {
		this.siteCodesDropdownResponse = null;
		this.suppliersDropdownResponse = null;
		this.programsDropdownResponse = null;
		this.commoditiesDropdownResponse = null;
		this.orgCodesDropdownResponse = null;
	}


	/*checkValidation(){
		if(this.capacityCheckedList.length <= 0 && this.capacityMitPlan == true){
			this.utilService.pushMsg('error', 'Error',
				'Must select any one of the rating from Capacity Risk Section.');
		}
	}*/

	downloadExcelReport() {
		let CompletedOrInProgress;
		this.spinnerFlag = true;

		if (this.completed == false && this.inProgress == true) {
			console.log("download inProgress records")
			CompletedOrInProgress = "N";
		}
		if (this.inProgress == false && this.completed == true) {
			console.log("download completed records")
			CompletedOrInProgress = "Y";
		}
		if (this.completed == true && this.inProgress == true) {
			console.log("download all records")
			CompletedOrInProgress = "YandN";
		}
		if (this.completed == false && this.inProgress == false) {
			CompletedOrInProgress = "not selected";
			console.log("no records for download")
			this.utilService.pushMsg('warn', 'Warning',
				'Must select either Completed eSRAs or In Progress eSRAs or both.');
			this.spinnerFlag = false;
		}
		if (CompletedOrInProgress != "not selected") {
			this.excelDownloadReport.excelReport(this.selectedSiteCodes,this.selectedSuppliers,this.selectedPrograms, this.selectedCommodities,this.selectedPMT,this.selectedVHM, this.keyCommodity1, this.esfCommodity1,
				this.greenBrownFields1, this.psaCheckedList, this.qualityCheckedList, this.capacityCheckedList, this.supplierCheckedList,
				this.programCheckedList, this.staCheckedList, this.qualityMitPlan, this.capacityMitPlan,
				this.supplierMitPlan, this.programMitPlan, CompletedOrInProgress,this.selectedSTAOrgCodes,
				this.employeeType,this.companyDomain,this.companyName).subscribe(data => {

				this.blob = new Blob([data], {type: 'application/vnd.ms-excel'});
				if (this.blob.size == 0) {
					this.utilService.pushMsg('warn', 'Warning',
						'No data found for the selected filter criteria.');
				} else {
					let downloadURL = window.URL.createObjectURL(data);
					let link = document.createElement('a');
					link.href = downloadURL;
					link.download = "eSRA-Reports_" + this.dateTime + ".xlsx";
					link.click();
				}
				this.spinnerFlag = false;
			});
		}
	}
}

/**This file contains a constant object that has properties that are necessary
 * for the build found in the `production` configuration in `angular.json`.
 */
export const environment = {
	production: true,
	 //envBaseURL :  "https://www.esra.ford.com",
	//envBaseURL :  "https://api.pd01i.gcp.ford.com/esraservice",
	envBaseURL :  "https://api.pd01e.gcp.ford.com/esraservice/",
	envEndPoint : 'https://corp.sts.ford.com/adfs/oauth2/authorize' +
				'?client_id=urn:esra:clientid:web_esraprodwebsite:prod' +
				'&resource=urn:esra:resource:web_esraprodwebsite:prod' +
					'&response_type=token' +
				'&redirect_uri=' + encodeURIComponent(window.location.origin + '/'),
	envName: "prod",
	apsLink : 'https://www.accessmgmt.ford.com'
	// include production api base url string as a property here
};

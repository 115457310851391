import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {MessageService} from 'primeng/api';

@Injectable({
	providedIn: 'root'
})
export class UtilService {
	constructor(private messageService: MessageService) {
	}

	pushMsg(msgSeverity: string, messageTitle: string, message: string) {
		let msgLife;
		if (messageTitle === 'Success' || messageTitle === 'Information') {
			msgLife = 5000;
		} else {
			msgLife = 7000;
		}
		this.messageService.clear();
		this.messageService.add({ severity: msgSeverity, summary: messageTitle, detail: message, life: msgLife });
	}


	linkify(plainLinkableText): string {
		if (plainLinkableText && plainLinkableText.trim() !== '') {
			let replacedLinkText;

			if (plainLinkableText.toLowerCase().startsWith('http') || plainLinkableText.startsWith('ftp')) {
				//URLs starting with http://, https://, or ftp://
				let httpReplacePattern = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
				replacedLinkText = plainLinkableText.replace(httpReplacePattern, '$1');
				//alert(replacedLinkText+"----------1")
			}
			//URLs starting with "www." (without // before it, or it'd re-link the ones done above).
			else if (plainLinkableText.toLowerCase().startsWith('www')) {
				let wwwReplacePattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
				replacedLinkText = plainLinkableText.replace(wwwReplacePattern, '$1');
				replacedLinkText = 'http://' + replacedLinkText;
				//alert(replacedLinkText+"----------2")
			}
			//Change email addresses to mailto:: links.
			else {
				let mailReplacePattern = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
				replacedLinkText = plainLinkableText.replace(mailReplacePattern, 'mailto:$1');
				//alert(replacedLinkText+"----------3")
			}
			console.log('Changed link ' + replacedLinkText);
			return replacedLinkText;
		}
	}

	overallTextColor: string;

	onLoadOverallTextColor(colorCode) {
		let diffColor = 'white';
		if (colorCode === 'GREEN') {
			this.overallTextColor = 'white';
			diffColor = 'green';
		} else if (colorCode === 'YELLOW') {
			this.overallTextColor = 'black';
			diffColor = 'yellow';
		} else if (colorCode === 'RED') {
			this.overallTextColor = 'white';
			diffColor = 'red';
		} else if (colorCode === 'BLUE') {
			this.overallTextColor = 'white';
			diffColor = 'blue';
		} else {
			diffColor = 'white';
		}
		return diffColor;
	}



}





import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import {GetSimData} from '../models/sim-details';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SitedetailsService {

  source:GetSimData;

  constructor(private http: HttpClient) { }

	//private apiURL = environment.envBaseURL+'/siteCodeDetails/';
	private apiURL = environment.envBaseURL;

	public getPSADetails(psaNumber): Observable<any> {
  		return this.http.get(this.apiURL+'psaData/PSA/'+psaNumber);
  	}

	public getPartDetails(psaNumber): Observable<any> {
		return this.http.get(this.apiURL+'psaParts/'+psaNumber);
	}

	public getSIMSiteDetails(siteCode): Observable<any> {
  		return this.http.get(this.apiURL+'siteData/'+siteCode);
	}

  	public getGSDBSiteDetails(siteCode): Observable<any> {
  		return this.http.get(this.apiURL+'gsdbSiteData/'+siteCode);
	}

	public getSIMSiteData(siteCode): Observable<any> {
  		return this.http.get(this.apiURL+'simData/'+siteCode);
	}

	public getCDSIdDetails(cdsId): Observable<any> {
		return this.http.get(this.apiURL+'lookUpCDSId/'+cdsId);
	}

	public reassignBuyer(cdsId): Observable<any> {
		return this.http.get(this.apiURL+'getReassignBuyerID/'+cdsId);
	}


}



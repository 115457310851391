import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';
import {Bulletins} from '../../models/bulletins-details';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class BulletinsService {

  constructor(private http: HttpClient) { }

  //private apiURL = environment.envBaseURL+'/siteCodeDetails/';
	private apiURL = environment.envBaseURL;

  create(bulletin: Bulletins): Observable<Bulletins> {
		return this.http.post<Bulletins>(this.apiURL,bulletin);
  }

  findAll(): Observable<Bulletins[]> {
		return this.http.get(this.apiURL).pipe(map((bulletins: Bulletins[]) => {
			return bulletins.map(bulletin => {
				return new Bulletins(bulletin);
			});
		}));
 }

  delete(id: number) {
		return this.http.delete<boolean>(this.apiURL+id);
  }

  findByCurrentDate(): Observable<Bulletins[]> {
		return this.http.get(this.apiURL + 'findByCurrentDate').pipe(map((bulletins: Bulletins[]) => {
			return bulletins.map(bulletin => {
				return new Bulletins(bulletin);
			});
		}));
	}

  update(id: number, bulletin: Bulletins): Observable<Bulletins> {
		return this.http.put<Bulletins>(this.apiURL+id,bulletin);
	}

  findById(id: number): Observable<Bulletins> {
		return this.http.get<Bulletins>(this.apiURL+ id);
	}
}

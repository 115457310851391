import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ExamplePageComponent} from './example-page/example-page.component';
import {HomeComponent} from './home/home.component';
import {CreateesraComponent } from './home/createesra/createesra.component';
import {ViewesraComponent } from './home/viewesra/viewesra.component';
import {CopyesraComponent } from './home/copyesra/copyesra.component';
import {UpdateesraComponent } from './home/updateesra/updateesra.component';
import {HelpComponent } from './help/help.component';
import {OAuthCallbackComponent} from './oauth/oauth-callback.component';
import {UrlConsumerService} from './oauth/url-consumer.service';
import {LoginComponent} from './oauth/login.component';
import {AuthGuardService} from './oauth/auth-guard.service';
import {NoAccessComponent} from './no-access/no-access.component';
import { EsraPrintComponent } from './esra-print/esra-print.component';
import {ManageBulletinsComponent} from './bulletin/manage-bulletins/manage-bulletins.component';
import {CreateBulletinsComponent} from './bulletin/create-bulletins/create-bulletins.component';
import {ReportsComponent} from './reports/reports.component';
//import { ComparativeSummaryComponent } from './home/viewesra/comparative-summary/comparative-summary.component'

const routes: Routes = [
	{
		path: 'access_token',
		component: OAuthCallbackComponent,
		canActivate: [UrlConsumerService]
	},
	{
		path: 'login',
		component: LoginComponent
	},

	{
		path: 'home',
		component: HomeComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'home/reassign',
		component: HomeComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'home/:success',
		component: HomeComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'home/:reOpenSRA/:siteCode/:psaNumber',
		component: HomeComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'example-page',
		component: ExamplePageComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'createesra/:siteCode/:psaNumber',
		component: CreateesraComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'updateesra/:siteCode/:psaNumber',
		component: UpdateesraComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'viewesra/:data/:type',
		component: ViewesraComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'viewesra/:psaNumber/:siteCode/:type',
		component: ViewesraComponent,
		canActivate: [AuthGuardService]
	},
	/**{
		path: 'comparativesummary/:psa/:psaNumber',
		component: ComparativeSummaryComponent,
		canActivate: [AuthGuardService]
	},*/

	{
		path: 'eprint/:psaNumber/:siteCode',
		component: EsraPrintComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'eprintByPSA/:psaNumber',
		component: EsraPrintComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'eprintBySite/:siteCode',
		component: EsraPrintComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'copyesra/:psaNumber/:siteCode',
		component: CopyesraComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'help',
		component: HelpComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'bulletin/manage-bulletins',
		component: ManageBulletinsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'bulletin/manage-bulletins/:success',
		component: ManageBulletinsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'bulletin/create-bulletins/:mode/:id',
		component: CreateBulletinsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'reports/reports',
		component: ReportsComponent,
		canActivate: [AuthGuardService]
	},

	{
		path: 'no-access',
		component: NoAccessComponent
	},
	{
		path: '',
		redirectTo: '/home',
		pathMatch: 'full',
		canActivate: [AuthGuardService]
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: true ,onSameUrlNavigation: 'reload'})],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule {}

import {Component,HostListener, OnInit} from '@angular/core';
import {UserIdService} from './services/user-id.service.service';
import {Bulletins} from './models/bulletins-details';
import {BulletinsService} from './services/bulletins/bulletins.service';
import {SpinnerService} from './services/spinner/spinner.service';
import { DatePipe } from "@angular/common";

/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit {

	/** Boolean for whether the menu is changed to collapsed 'hamburger menu' or full top horizontal menu */
	hamMenu: boolean;

	userName;
	userID;
	employeeType;
	companyName;
	companyCode;
	today: Date = new Date();
	pipe = new DatePipe('en-US');

	bulletinsToShow: Bulletins[];

	showUserId = true;

	constructor(private userIdService: UserIdService,public bulletinService: BulletinsService,public spinnerService: SpinnerService) {
	}

	ngOnInit() {

		this.userIdService.userName.subscribe((value: string) => {
			this.userName = value;
			console.log("UserName::"+this.userName)
			if(this.userName != 'User Name Not Available') {//dev
				//if(this.userName == 'User Name Not Available') {//local
				this.getBulletin();
			}
		});

		this.userIdService.userID.subscribe((value: string) => {
			this.userID = value.toUpperCase();
			console.log("UserID::"+this.userID)
		});

		this.userIdService.employeeType.subscribe((value: string) => {
			this.employeeType = value.toUpperCase();
			console.log("Employee Type::"+this.employeeType)
		});

		this.userIdService.companyName.subscribe((value: string) => {
			this.companyName = value;
			console.log("Company Name::"+this.companyName)
		});

		this.userIdService.companyCode.subscribe((value: string) => {
			this.companyCode = value;
			console.log("Company Code::"+this.companyCode)
		});

		if (window.location.href.includes('eprint')) {
				this.showUserId = false;
		}
	}

	/** A listener to toggle between full screen menu and collapsed hamburger menu for smaller screens */
	@HostListener('window:resize', ['$event']) onResize(event?) {
		if (event.target.innerWidth > 767) {
			this.hamMenu = false;
		}
	}

	checkSpinner() {
		//alert("hello----------"+this.spinnerService.spinner.value)
		//return this.spinnerService.spinner.value;
		return true;
	}

	private getBulletin() {
		this.bulletinService.findByCurrentDate().subscribe(response => {
			this.bulletinsToShow = response;
		});
	}
}











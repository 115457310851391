import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BulletinsService} from '../../services/bulletins/bulletins.service';
import {Bulletins, emptyBulletin} from '../../models/bulletins-details';
import {MessageService, Message} from 'primeng/api';
import {UtilService} from '../../services/util.service';

@Component({
	selector: 'create-bulletins',
	templateUrl: './create-bulletins.component.html',
	styleUrls: ['./create-bulletins.component.scss']
})
export class CreateBulletinsComponent implements OnInit {
	constructor(private activatedRoute: ActivatedRoute, private bulletinService: BulletinsService,
              private messageService: MessageService, private router: Router, private utilService: UtilService) {
	}
	private mode: string;
	private displayName: string;
	private bulletinObj: Bulletins = new Bulletins(emptyBulletin);

	private buttonName: string;
	private disableCreateButton = false;
	private startDateDisplay: Date;
	private endDateDisplay: Date;

	message: Message[] = [];

	bulletins: Bulletins[];

	date: Date;

	ngOnInit() {
		this.date = new Date();
		this.retrieveRouteParameter();
		if (this.mode === 'create') {
			this.initializeScreen();
		} else if (this.mode === 'edit') {
			this.findById(this.bulletinObj.id);
		}
		this.validateCreateButton();
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.mode = params.get('mode');
			this.bulletinObj.id = Number(params.get('id'));
			if (this.mode === 'create') {
				this.displayName = 'Create New Bulletin';
				this.buttonName = 'Create New';
			} else if (this.mode === 'edit') {
				this.displayName = 'Edit Bulletin';
				this.buttonName = 'Update';
			}
			console.log('this.displayName ' + this.displayName);
		});
	}

	private initializeScreen() {
		this.bulletinObj = new Bulletins(emptyBulletin);
	}

	onSubmit() {
		this.bulletinObj.startDate = this.startDateDisplay.toISOString();
		this.bulletinObj.endDate = this.endDateDisplay.toISOString();
		if (this.mode === 'create') {
			this.onCreate(this.bulletinObj);
		} else if (this.mode === 'edit') {
			this.onUpdate(this.bulletinObj);
		}
	}

	private onCreate(newBulletin: Bulletins) {
		if (this.validateFields(newBulletin)) {
			this.bulletinService.findAll().subscribe((response: Bulletins[]) => {
				this.bulletins = response;
				if (response.length === 0) {
					this.bulletinService.create(newBulletin).subscribe(success => {
						//sessionStorage.setItem('bulletinCreate', 'CreateSuccess');
						this.utilService.pushMsg('success', 'Success', 'New Bulletin has been created successfully.');
						this.goToManageScreen();
					}, createError => {
						this.utilService.pushMsg('error', 'Error', 'Error while creating new Bulletin');
					});
				} else {
					let isNotExists = true;
					this.bulletins.forEach(bulletin => {
							if (bulletin.title === newBulletin.title) {
								isNotExists = false;
								this.utilService.pushMsg('warn', 'Warning', 'The Bulletin title already exists.');
						}
					});
					if (isNotExists) {
						this.bulletinService.create(newBulletin).subscribe(success => {
							//sessionStorage.setItem('bulletinCreate', 'CreateSuccess');
							this.utilService.pushMsg('success', 'Success', 'New Bulletin has been created successfully.');
							this.goToManageScreen();
						});
					}
				}
			});
		}
	}

	private validateFields(bulletin: Bulletins): boolean {
		if (bulletin.title === null || bulletin.title.trim() === '') {
			this.message.push({severity: 'error', summary: 'Error', detail: 'Please enter Bulletin Title'});
			return false;
		} else if (bulletin.description === null || bulletin.description.trim() === '') {
			this.message.push({severity: 'error', summary: 'Error', detail: 'Please enter Bulletin Description'});
			return false;
		} else if (bulletin.startDate === null || bulletin.startDate.trim() === '') {
			this.message.push({severity: 'error', summary: 'Error', detail: 'Please enter Start Date value'});
			return false;
		} else if (bulletin.endDate === null || bulletin.endDate.trim() === '') {
			this.message.push({severity: 'error', summary: 'Error', detail: 'Please enter End Date value'});
			return false;
		} else if ((bulletin.startDate != null && bulletin.endDate != null) && (bulletin.endDate) < (bulletin.startDate)) {
			this.message.push({severity: 'error', summary: 'Error', detail: 'End date should be greater then start date.'});
			return false;
		} else {
			return true;
		}
	}
	private validateCreateButton() {
		if (this.bulletinObj.title && this.bulletinObj.title.trim() !== '' && this.bulletinObj.description &&
			this.bulletinObj.description.trim() !== '' && (this.startDateDisplay || this.bulletinObj.startDate) &&
			(this.endDateDisplay || this.bulletinObj.endDate)) {
			this.disableCreateButton = false;
		} else {
			this.disableCreateButton = true;
		}
	}

	/*private goToManageScreen(value: string) {
		this.router.navigate(['bulletin/manage-bulletins',value]);
	}*/
	private goToManageScreen() {
		this.router.navigate(['bulletin/manage-bulletins']);
	}
	onCancel() {
		this.utilService.pushMsg('info', 'Information', 'Operation Cancelled');
		this.goToManageScreen();
	}

	private onUpdate(updateBulletin: Bulletins) {
		if (this.validateFields(updateBulletin)) {
			this.bulletinService.update(updateBulletin.id, updateBulletin).subscribe(success => {
				//sessionStorage.setItem('bulletinUpdate', 'UpdateSuccess');
				this.utilService.pushMsg('success', 'Success', 'Bulletin has been successfully modified.');
				this.goToManageScreen();
			}, createError => {
				this.utilService.pushMsg('error', 'Error', 'Error while updating Bulletin');
			});
		}
	}
	private findById(id: number) {
		this.bulletinService.findById(id).subscribe(response => {
			this.bulletinObj = response;
			this.startDateDisplay = new Date(this.bulletinObj.startDate + 'GMT+0');
			this.endDateDisplay = new Date(this.bulletinObj.endDate + 'GMT+0');
		});
	}
}
